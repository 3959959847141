<template>
  <section>
    <div style="min-height: 5000px;" class="container-fluid">
      <uspCartao titulo="Folhas de pagamento">
        <template slot="corpo">
          <div class="row">
            <div class="col">
              <button class="btn btn-primary" :disabled="podeMesAnterior == 'N'" @click="listarMesAnterior">&nbsp;&nbsp;&lt;&lt;&nbsp;&nbsp;</button>
            </div>
            <div class="col text-center align-middle pt-1">
              {{ mesAtual }}
            </div>
            <div class="col text-right">
              <button class="btn btn-primary" :disabled="podeMesPosterior == 'N'" @click="listarMesPosterior">&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;</button>
            </div>
          </div>
          <div class="btn-group float-right mt-3 mb-3" role="group">
            <a target="_blank" :href="'/proxy/folha/servicos/planilhaDifRetro?dtactcfol=' + mesAtual.substring(3, 7) + '-' + mesAtual.substring(0, 2) + '-01&tippcsfol='" class="btn btn-secondary">Planilha das normais / avulsas</a>
            <a target="_blank" :href="'/proxy/folha/servicos/planilhaDifRetro?dtactcfol=' + mesAtual.substring(3, 7) + '-' + mesAtual.substring(0, 2) + '-01&tippcsfol=Dif'" class="btn btn-secondary ml-2 mr-2">Planilha das diferenças</a>
            <a target="_blank" :href="'/proxy/folha/servicos/planilhaDifRetro?dtactcfol=' + mesAtual.substring(3, 7) + '-' + mesAtual.substring(0, 2) + '-01&tippcsfol=Retro'" class="btn btn-secondary">Planilha das retroativas</a>
          </div>
          <div class="table-responsive mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" style="width: 10%;">Folha/<br>Processamento</th>
                  <th scope="col" style="width: 10%;">Nome</th>
                  <th scope="col" style="width: 10%;">Encerramento de cadastro</th>
                  <th scope="col" style="width: 10%;">Situação</th>
                  <th scope="col" style="width: 10%;">Servidores</th>
                  <th scope="col" style="width: 10%;">Total de vantagens (R$)</th>
                  <th scope="col" style="width: 10%;">Mensagens</th>
                  <th scope="col" style="width: 10%;">Data de pagamento</th>
                  <th scope="col" style="width: 10%;">Último procedimento</th>
                  <th scope="col" style="width: 10%;">Processamento</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in folhas" :class="item.classe">
                  <td><div class="form-check"><input type="checkbox" v-model="item.selecionado" @change="selecionarFolha(item)" class="form-check-input" :id="'codfolpag' + item.codfolpag"><label class="form-check-label">{{ item.codfolpag }}/<br>{{ item.numseqpcsfol }}</label></div></td>
                  <td>{{ item.nomfol }}</td>
                  <td>{{ item.dtaenccadfol2 | dataHora }}</td>
                  <td>{{ item.stafol | stafol }}</td>
                  <td>{{ item.qtdfunfol != '' ? Number(item.qtdfunfol).toLocaleString() : '' }}</td>
                  <td>{{ Number(item.vlrpag).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                  <td>
                    <span v-show="item.numseqpcsfol">
                      <span v-if="item.erros > 0">
                        <a href="#" @click.prevent="exibirMensagens(item, 'E')" class="text-danger"><i class="fas fa-bug"></i>&nbsp;{{ item.erros }}</a>
                      </span>
                      <span class="text-dark" v-else>
                        <i class="fas fa-bug"></i>&nbsp;{{ item.erros }}
                      </span>
                      <br>
                      <span v-if="item.alertas > 0">
                        <a href="#" @click.prevent="exibirMensagens(item, 'A')" class="text-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;{{ item.alertas }}</a>
                      </span>
                      <span class="text-dark" v-else>
                        <i class="fas fa-exclamation-triangle"></i>&nbsp;{{ item.alertas }}
                      </span>
                      <br>
                      <span v-if="item.info > 0">
                        <a href="#" @click.prevent="exibirMensagens(item, 'I')" class="text-primary"><i class="fas fa-info-circle"></i>&nbsp;{{ item.info }}</a>
                      </span>
                      <span class="text-dark" v-else>
                        <i class="fas fa-info-circle text-"></i>&nbsp;{{ item.info }}
                      </span>
                    </span>
                  </td>
                  <td>{{ item.dtapagfol }}</td>
                  <td>{{ item.tippcdfol | situacao }}</td>
                  <td>
                    <router-link title="Processamento" v-show="!item.numseqpcsfol" :to="{path: '/novaFolha', query: {codfolpag: item.codfolpag, ano: item.ano, mes: item.mes, codtipfol: item.codtipfol, tippcsfol: item.tippcsfol}}" target="_blank">
                      <i class="fas fa-plus"></i>
                    </router-link>
                    <a
                        href="#"
                        v-show="item.numseqpcsfol"
                        title="Procedimentos"
                        @click.prevent="selecionar(item)"
                        data-toggle="modal"
                        data-target="#procedimentos"
                      >
                        <i class="fas fa-edit"></i>
                      </a>
                    <router-link title="Análise" v-show="item.numseqpcsfol" :to="{path: '/novaFolha/analise', query: {codfolpag: item.codfolpag, numseqpcsfol: item.numseqpcsfol}}" target="_blank" class="ml-2">
                      <i class="fas fa-info-circle"></i>
                    </router-link>
                    <router-link title="Reprocessar" v-show="item.numseqpcsfol && item.concluiu == 'N'" :to="{path: '/novaFolha', query: {codfolpag: item.codfolpag, ano: item.ano, mes: item.mes, codtipfol: item.codtipfol, tippcsfol: item.tippcsfol, reprocessar: true}}" target="_blank" class="ml-2">
                      <i class="fas fa-sync-alt"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <uspModal :titulo="tituloMensagem" ref="mensagens" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
          <template slot="body">
            <table class="table mt-3 mb-0">
              <thead>
                <tr>
                  <th scope="col">N. USP</th>
                  <th scope="col">Seq.</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Mensagem(ns)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaMensagem" v-bind:key="item.codpes + '|' + item.numseqsrv + '|' + item.codgrpcon">
                  <td>{{ item.codpes }}</td>
                  <td>{{ item.numseqsrv }}</td>
                  <td>{{ item.codgrpcon }}</td>
                  <td>{{ item.nompes }}</td>
                  <td>{{ item.msgpcs }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="footer">
              <button type="button" class="btn btn-secondary" @click="fecharModal('mensagens')">Fechar</button>
          </template>
      </uspModal>
      <uspModal :titulo="titulo" ref="procedimentos" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
          <template slot="body">
            <div class="row">
              <div class="col">
                <select class="form-control" v-model="procedimentoSelecionado" :disabled="executando" @change="mostrarLogs()">
                  <option value=""></option>
                  <option class="text-monospace" v-for="item in procedimentos" :value="item"><span v-show="mostrarFolhaNaListaDeProced">{{ item.codfolpag}}/{{ String(item.numseqpcsfol).padStart(2, '0') }}&nbsp;|&nbsp;</span>{{ item.tippcdfol | situacao }} em {{ item.dtaexepcdfol | dataHora }} por {{ item.nompes }}</option>
                </select>
              </div>
              <div class="col text-right">
                <select style="text-indent: 5px;" class="form-control" v-model="procedimento" :disabled="executando" @change="executar()">
                  <option value="">Executar procedimento:</option>
                  <option v-for="item in tiposProcedimento" v-show="folhas.filter(f => f.selecionado).filter(f => f['pode' + item] == '').length == 0" :value="item">{{ item | situacao }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <textarea readonly v-model="logs" class="form-control text-monospace" id="logs" style="height: 600px;"></textarea>
              </div>
            </div>
          </template>
          <template slot="footer">
              <button type="button" class="btn btn-secondary" @click="fecharModal('procedimentos')">Fechar</button>
          </template>
      </uspModal>
    </div>
  </section>
</template>
<script>
import NovaFolhaServico from "../dominio/novaFolha/NovaFolhaServico";
const api = NovaFolhaServico.build({});

export default {
  name: "Calendario",
  computed: {
  },
  data() {
    return {
      folhas: [],
      id: "",
      procedimentoSelecionado: {},
      procedimentosPendentes: [],
      podeMesAnterior: "",
      mesAnterior: "",
      podeMesPosterior: "",
      mesPosterior: "",
      mesAtual: "",
      procedimento: "",
      tiposProcedimento: ["V", "P", "C", "M", "T"],
      procedimentos: [],
      executando: false,
      titulo: "",
      tituloMensagem: "",
      listaMensagem: [],
      logs: "",
      windowWidth: window.innerWidth
    };
  },
  filters: {
    situacao: function(value) {
      if (value == "P") return "Resumo folha e liquidação de pagamento";
      if (value == "V") return "Verificação de processamento";
      if (value == "C") return "Consolidação de processamento";
      if (value == "M") return "Realimentação do Marteweb";
      if (value == "L") return "Execução de processamento"
      if (value == "T") return "Teste";
      return "";
    },
    stafol: function(value) {
      if (value == "A") return "Aberta";
      if (value == "C") return "Consolidada";
      if (value == "E") return "Encerrada";
      if (value == "P") return "Em processamento";
      if (value == "V") return "Aguardando validação";
      if (value == "S") return "Folha sem eleitos";
      return "";
    },
    dataHora: function(value) {
      if (value) {
        return value.substring(0, 16);
      }
      return "";
    }
  },
  watch: {
    
  },
  computed: {
    mostrarFolhaNaListaDeProced: function() {
      const self = this;
      return self.folhas.filter(f => f.selecionado).length > 1;
    }
  },
  methods: {
    listarFolhasComProced(callback) {
      const self = this;
      self.folhas = [];
      self.podeMesAnterior = 'N';
      self.podeMesPosterior = 'N';
      self.$forceUpdate();
      api.listarFolhasComProced({dtactcfol: (self.mesAtual ? "01/" + self.mesAtual : "")}).then(function(resposta) {
        self.folhas = resposta;
        for (let i in self.folhas) {
          let concluiu = (((self.folhas[i].stafol == 'C' && self.folhas[i].tippcdfol == 'M') 
              || (self.folhas[i].stafol == 'S' && self.folhas[i].tippcdfol == 'P')) 
              && self.folhas[i].numseqpcsfol);
          self.folhas[i].concluiu = (concluiu ? 'S' : 'N');
          let ok = self.folhas[i].data == 'depois' || concluiu;
          if (!ok) {
            self.folhas[i].classe = "font-weight-bold text-danger";
          }
          if (self.folhas[i].logexepcd) {
            let lines = self.folhas[i].logexepcd.split("\n");
            self.folhas[i].ultima_linha = lines[lines.length - 1];
          }
        }
        self.mesAtual = resposta[0].mes_atual;
        self.podeMesAnterior = resposta[0].pode_mes_anterior;
        self.mesAnterior = resposta[0].mes_anterior;
        self.podeMesPosterior = resposta[0].pode_mes_posterior;
        self.mesPosterior = resposta[0].mes_posterior;
        if (callback) {
          callback();
        }
      });
    },
    listarMesAnterior() {
      const self = this;
      self.mesAtual = self.mesAnterior;
      self.listarFolhasComProced();
    },
    listarMesPosterior() {
      const self = this;
      self.mesAtual = self.mesPosterior;
      self.listarFolhasComProced();
    },
    listarProcedimentos() {
      const self = this;
      self.procedimentos = [];
      self.$forceUpdate();
      let parametros = {};
      if (self.folhas.filter(f => f.selecionado).length == 1) {
        parametros = self.folhas.filter(f => f.selecionado)[0];
      } else {
        parametros = {'codfolpag': '', 'numseqpcsfol': '', 'parametros': self.folhas.filter(f => f.selecionado).map(f => "'" + f.codfolpag + '/' + f.numseqpcsfol + "'").join(",")};
      }
      api.listarProcedimentos(parametros)
          .then(function(resposta) {
        self.procedimentos = resposta;
        if (self.procedimentos.length > 0) {
          self.procedimentoSelecionado = self.procedimentos[self.procedimentos.length - 1];
          self.mostrarLogs();
        }
      });
    },
    mostrarLogs() {
      const self = this;
      self.logs = "";
      for (let i in self.procedimentos) {
        if (self.procedimentos[i].codfolpag == self.procedimentoSelecionado.codfolpag
            && self.procedimentos[i].numseqpcsfol == self.procedimentoSelecionado.numseqpcsfol
            && self.procedimentos[i].numseqpcdfol == self.procedimentoSelecionado.numseqpcdfol) {
          self.logs = self.procedimentos[i].logexepcd;
        }
      }
    },
    selecionar(item) {
      const self = this;
      if (!item.selecionado) {
        for (let i in self.folhas) {
          self.folhas[i].selecionado = false;
        }
        item.selecionado = true;
      }
      self.titulo = self.folhas.filter(f => f.selecionado).map(f => f.codfolpag + '/' + f.numseqpcsfol).join(', ');
      self.procedimento = "";
      self.numseqpcdfol = "";
      self.listarProcedimentos();
      self.$refs['procedimentos'].openModal();
      self.logs = "";
    },
    selecionarFolha(item) {
      const self = this;
      if (item.selecionado) {
        if (self.folhas.filter(f => f.selecionado).length == 1) {
          const selecionado = self.folhas.filter(f => f.selecionado)[0];
          self.folhas.filter(f => f.dtaenccadfol2 == selecionado.dtaenccadfol2).forEach(f => {
            f.selecionado = true;
          });
          self.$forceUpdate();
        }
      }
    },
    executarProximoProcedimento(codfolpag, numseqpcsfol, procedimento) {
      const self = this;
      self.executando = true;
      const interval = setInterval(function() {
        api.consultarProcedimento({id: self.id}).then(function(resposta) {
          if (resposta.logs != self.logs) {
            self.logs = resposta.logs;
            self.$forceUpdate();
            setTimeout(function() {
              document.getElementById("logs").scrollTop = 1000000;
            }, 1000);
          }
        });
      }, 5 * 1000);
      api.executarProcedimento({id: self.id, codfolpag: codfolpag, numseqpcsfol: numseqpcsfol, tipo: procedimento}).then(function(resposta) {
        self.logs = resposta.logs;
      }).finally(function() {
        clearInterval(interval);
        if (self.procedimentosPendentes.length == 0) {
          const selecionadas = self.folhas.filter(f => f.selecionado).map(f => f.codfolpag + "/" + f.numseqpcsfol);
          self.listarFolhasComProced(function() {
            self.folhas.filter(f => selecionadas.indexOf(f.codfolpag + "/" + f.numseqpcsfol) > -1).forEach(f => f.selecionado = true);
            self.executando = false;
            self.listarProcedimentos();
          });
        } else {
          const proximo = self.procedimentosPendentes.pop();
          self.executarProximoProcedimento(proximo.codfolpag, proximo.numseqpcsfol, procedimento);
        }
      });
    },
    executar() {
      const self = this;
      if (self.procedimento != "") {
        if (confirm("Confirma execução do procedimento " + self.$options.filters.situacao(self.procedimento) + " para o(s) processamento(s) " + self.folhas.filter(f => f.selecionado).map(f => f.codfolpag + "/" + f.numseqpcsfol).join(", ") + "?")) {
          self.procedimentoSelecionado = {};
          self.mostrarLogs();
          self.procedimentosPendentes = self.folhas.filter(f => f.selecionado);
          self.procedimentosPendentes.reverse();
          const proximo = self.procedimentosPendentes.pop();
          self.executarProximoProcedimento(proximo.codfolpag, proximo.numseqpcsfol, self.procedimento);
        } else {
          self.procedimento = "";
        }
      }
    },
    exibirMensagens(item, nivmsgpcs) {
      var self = this;
      self.$refs['mensagens'].openModal();
      self.tituloMensagem = item.codfolpag + "/" + item.numseqpcsfol + " - " + (nivmsgpcs == 'E' ? "Erros" : (nivmsgpcs == "A" ? "Alertas" : (nivmsgpcs == "I" ? "Informações" : "")));
      self.listaMensagem = [];
      self.$forceUpdate();
      api.listarMensagem(Object.assign({}, item, {'nivmsgpcs': nivmsgpcs})).then(function(lista) {
        self.listaMensagem = lista;
      });
    },
    fecharModal(ref) {
      this.$refs[ref].closeModal();
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted() {
    const self = this;
    api.novaSessao().then(function(resposta) {
        self.id = resposta.id;
    });
    self.listarFolhasComProced();
    self.$nextTick(() => {
      window.addEventListener('resize', self.onResize);
    });
  }
};
</script>
<style lang="scss" scoped>

</style>