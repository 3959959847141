<template>
  <section style="min-height: 1000px;">
    <div class="container-fluid">
      <uspCartao titulo="Cargas de dados">
        <template slot="corpo">
          <button class="btn btn-primary" @click.prevent="disparar()" :disabled="carregando">Disparar nova carga de dados</button>
          <div class="progress mt-3" style="height: 20px;" v-show="progresso > 0">
            <div class="progress-bar" role="progressbar" :style="'width: ' + progresso + '%'"></div>
          </div>
          <div v-show="mensagem != ''" class="alert alert-primary mt-3" role="alert">
              {{ mensagem }}
          </div>
          <table class="table mt-3">
            <thead>
              <tr>
                <th scope="col" style="width: 25%;">Início</th>
                <th scope="col" style="width: 25%;">Fim</th>
                <th scope="col" style="width: 50%;">Usuário</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="item in listaCarga" :key="item.inicio">
                    <td>{{ item.dtahorinicga2 }}</td>
                    <td>{{ item.dtahorfimcga2 }}</td>
                    <td>{{ item.nompes  }}</td>
                </tr>
            </tbody>
          </table>
        </template>
      </uspCartao>
    </div>
  </section>
</template>
<script>
import NovaFolhaServico from "../dominio/novaFolha/NovaFolhaServico";
const api = NovaFolhaServico.build({});
export default {
  name: "CargaDados",
  data() {
    return {
        listaCarga: [],
        progresso: 0,
        carregando: false,
        mensagem: ""
    };
  },
  methods: {
    listarCarga() {
      var self = this;
      self.listaCarga = [];
      self.$forceUpdate();
      api.listarCargaIncremental().then(function(lista) {
        self.listaCarga = lista;
        for (var i in lista) {
            if (lista[i].dtahorfimcga2 == "") {
                self.carregando = true;
            }
        }
      });
    },
    disparar() {
        var self = this;
        self.carregando = true;
        api.dispararCargaIncremental().then(function(resposta) {
            self.mensagem = resposta.mensagem;
            if (self.mensagem != "") {
                self.carregando = false;
            }
        }).catch(function(resposta) {
            self.carregando = false;
            self.mensagem = resposta.mensagem;
        });
    }
  },
  mounted() {
    var self = this;
    self.listarCarga();
    setInterval(function() {
        if (self.carregando) {
            api.acompanharCargaIncremental().then(function(resposta) {
                self.progresso = resposta.porcentual;
                if (resposta.porcentual == 1) {
                    self.carregando = false;
                    self.progresso = 0;
                    self.listarCarga();
                }
            });
        }
    }, 10 * 1000);
  }
};
</script>
<style>
</style>
