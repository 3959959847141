import { PortalServico } from '@/utils/';

/**
 * @type {NovaFolhaServico}
 */
export default class NovaFolhaServico extends PortalServico {

    novaSessao() {
        return this.post('/folha/servicos/novaSessao', {});
    }

    processar(params) {
        return this.post('/folha/servicos/processar', params);
    }

    acompanhar(params) {
        return this.post('/folha/servicos/acompanhar', params);
    }

    progresso(params) {
        return this.post('/folha/servicos/progresso', params);
    }

    listarSimulacoes() {
        return this.post('/folha/servicos/simulacoes', {});
    }

    consultarDados(params) {
        return this.post("/folha/servicos/cargaDados", params);
    }

    listarFolhasComProced(params) {
        return this.post("/uspproc/listar/folha_prod/folListarFolhasComProced", params);
    }

    executarProcedimento(params) {
        return this.post("/folha/servicos/executarProcedimento", params);
    }

    consultarProcedimento(params) {
        return this.post("/folha/servicos/consultarProcedimento", params);
    }

    listarProcedimentos(params) {
        return this.post("/uspproc/listar/folha_prod/folListarProcedimentos", params);
    }

    consolidarProc(params) {
        return this.post("/uspproc/executar/folha_prod/folConsolidarProc", params);
    }

    listarTabelas() {
        return this.post("/folha/servicos/tabelas", {});
    }

    listarDadosAlteracoes(params) {
        return this.post('/uspproc/listar/folha_prod/folListarAlteracoes', params);
    }

    obterUsuarioLogado() {
        return this.post('/uspproc/obter/folha_prod/resFolObterUsuarioLogado', {});
    }

    listarComparacao(params) {
        return this.post('/uspproc/listar/folha_prod/folListarDiffCaso', params);
    }

    listarIndice(params) {
        return this.post('/uspproc/listar/folha_prod/folCalcularIndices', params);
    }

    listarPermissoes(params) {
        return this.post('/uspproc/executar/folha_prod/resFolPermissao', params);
    }

    listarFolhas(params) {
        return this.post('/uspproc/listar/folha_prod/folListarFOLHAPGTO', params);
    }

    listarTiposFolha() {
        return this.post("/uspproc/listar/folha_prod/folListarTIPOFOLHA", {});
    }

    listarUnidade() {
        return this.post("/uspproc/listar/folha_prod/folListarUNIDADE", {});
    }

    listarTiposProcessamento() {
        return this.post("/uspproc/listar/folha_prod/folListarDOMTIPOFOLHA", {});
    }

    listarProcLegado(params) {
        return this.post("/uspproc/listar/folha_prod/folListarProcLegado", params);
    }

    listarProcs(params) {
        return this.post("/uspproc/listar/folha_prod/folListarPROCESSAMENTO", params);
    }

    listarResumoPagamento(params) {
        return this.post("/uspproc/listar/folha_prod/folListarResumoPagamento", params);
    }

    listarResumoMovimento(params) {
        return this.post("/uspproc/listar/folha_prod/folListarResumoMovimento", params);
    }

    listarExcecoes(params) {
        return this.post("/uspproc/listar/folha_prod/folListarFECHAMENTOEXCECAO", params);
    }

    alterarProc(params) {
        return this.post("/uspproc/executar/folha_prod/folAlterarProcessamento", params);
    }

    salvarObsServidor(params) {
        return this.post("/uspproc/executar/folha_prod/folSalvarObservacao", params);
    }

    atualizarExclusaoServ(params) {
        return this.post("/uspproc/executar/folha_prod/folAtualizarExclusaoServ", params);
    }

    carregarExclusoes(params) {
        return this.post("/uspproc/executar/folha_prod/folCarregarExclusoes", params);
    }

    excluirProc(params) {
        return this.post("/uspproc/executar/folha_prod/folExcluirProcessamento", params);
    }

    listarPagamentosServ(params) {
        return this.post("/uspproc/listar/folha_prod/folListarPagamentosServ", params);
    }

    listarMovimentosServ(params) {
        return this.post("/uspproc/listar/folha_prod/folListarMovimentosServ", params);
    }

    listarMotivosRetro(params) {
        return this.post("/uspproc/listar/folha_prod/folListarMotivosRetro", params);
    }

    listarMensagem(params) {
        return this.post("/uspproc/listar/folha_prod/folListarMensagem", params);
    }

    listarAnaliseDif(params) {
        return this.post("/uspproc/listar/folha_prod/folListarAnaliseDif", params);
    }

    listarIntermedRetro(params) {
        return this.post("/uspproc/listar/folha_prod/folListarIntermedRetro", params);
    }

    listarIntermedCompar(params) {
        return this.post("/uspproc/listar/folha_prod/folListarIntermedCompar", params);
    }

    listarServidores(params) {
        return this.post("/uspproc/listar/folha_prod/folListarServidoresProc", params);
    }

    listarCargaIncremental() {
        return this.post("/uspproc/listar/folha_prod/folListarCargaIncremental", {});
    }

    listarUltimasCargas() {
        return this.post("/uspproc/listar/folha_prod/folListarUltimasCargas", {});
    }

    dispararCargaIncremental() {
        return this.post('/folha/servicos/dispararCargaIncremental', {});
    }

    acompanharCargaIncremental() {
        return this.post('/folha/servicos/acompanharCargaIncremental', {});
    }

    obterNomePessoa(params) {
        return this.post("/uspproc/listar/folha_prod/folObterNomePessoa", params);
    }

    obterNomePessoaLote(params) {
        return this.post("/uspproc/listar/batch/folha_prod/folObterNomePessoa", params);
    }

    obterLogsProc(params) {
        return this.post("/uspproc/listar/folha_prod/folObterLogsProc", params);
    }

    obterMemoriaCalc(params) {
        return this.post("/uspproc/listar/folha_prod/folObterMemoriaCalc", params);
    }

    obterProcLegado(params) {
        return this.post("/uspproc/listar/folha_prod/folObterProcLegado", params);
    }

    obterDadosProc(params) {
        return this.post("/uspproc/listar/folha_prod/folObterDadosProc", params);
    }
    
    listarDiffCaso(params) {
        return this.post("/uspproc/listar/folha_prod/folListarDiffCaso", params);
    }

    listarDiff(params) {
        return this.post("/uspproc/listar/folha_prod/folListarDiff", params);
    }
}
