<template>
  <section>
    <div style="min-height: 5000px;" class="container-fluid">
      <uspCartao titulo="Folhas de pagamento" :fechado="fechado['folhas']">
        <template slot="corpo">
          <div>Se precisar de ajuda, voc&ecirc; pode baixar nosso <a target="_blank" href="https://uspdigital.usp.br/comumwebdev/arquivos/portal/nova_folha_ajuda.pdf">manual de instru&ccedil;&otilde;es</a>!</div>
          <form class="mt-3">
            <div class="form-row">
              <div class="form-group col-md-3">
                <label>Ano</label>
                <select v-model="folha.ano" class="form-control">
                  <option :value="ano" :key="ano" v-for="ano in listaAno">{{ ano }}</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label class="w-100">
                  M&ecirc;s
                  <div class="float-right">
                    <a href="#" title="Mês anterior" @click.prevent="mesAnterior()">
                      <i class="fas fa-angle-left"></i>
                    </a>&nbsp;&nbsp;
                    <a @click.prevent="mesAtual()" href="#" title="Mês atual">
                      <i class="fas fa-calendar-day"></i>
                    </a>&nbsp;&nbsp;
                    <a @click.prevent="mesPosterior()" href="#" title="Mês posterior">
                      <i class="fas fa-angle-right"></i>
                    </a>
                  </div>
                </label>
                <select v-model="folha.mes" class="form-control">
                  <option value="1">Janeiro</option>
                  <option value="2">Fevereiro</option>
                  <option value="3">Mar&ccedil;o</option>
                  <option value="4">Abril</option>
                  <option value="5">Maio</option>
                  <option value="6">Junho</option>
                  <option value="7">Julho</option>
                  <option value="8">Agosto</option>
                  <option value="9">Setembro</option>
                  <option value="10">Outubro</option>
                  <option value="11">Novembro</option>
                  <option value="12">Dezembro</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Tipo</label>
                <select v-model="folha.codtipfol" class="form-control">
                  <option value></option>
                  <option
                    v-for="item in tiposFolha"
                    :value="item.codtipfol"
                    :key="item.codtipfol"
                  >{{ item.tipfol }}</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Execu&ccedil;&atilde;o</label>
                <select v-model="folha.tippcsfol" class="form-control">
                  <option value></option>
                  <option
                    v-for="item in tiposProcessamento"
                    :value="item.tippcsfol"
                    :key="item.tippcsfol"
                  >{{ item.tippcsfol }}</option>
                </select>
              </div>
            </div>
            <div v-if="folhaNaoEncontrada" class="alert alert-primary" role="alert">
              Não existe folha de pagamento cadastrada para os parâmetros selecionados.
            </div>
            <div
              class="form-row table-responsive"
              style="margin-top: 20px;"
            >
              <table class="table" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 5%;"></th>
                    <th scope="col" style="width: 10%;">C&oacute;digo</th>
                    <th scope="col" style="width: 30%;">Folha</th>
                    <th scope="col" style="width: 15%;">Encerramento de cadastro</th>
                    <th scope="col" style="width: 15%;">Tipo</th>
                    <th scope="col" style="width: 10%;">Execu&ccedil;&atilde;o</th>
                    <th scope="col" style="width: 10%;">Situa&ccedil;&atilde;o</th>
                    <th scope="col" style="width: 5%;" class="text-right">
                        <div class="spinner-border spinner-border-sm" role="status" v-show="carregandoFolha">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="folhas.length > 0">
                  <tr v-for="item in folhas" :key="item.codfolpag">
                    <td>
                      <input
                        type="radio"
                        name="folhaSelecionada"
                        v-model="folhaSelecionada"
                        :value="item.codfolpag"
                      />
                    </td>
                    <td>{{ item.codfolpag }}</td>
                    <td>{{ item.nomfol }}</td>
                    <td>{{ item.dtaenccadfol }}</td>                    
                    <td>{{ item.tipfol }}</td>
                    <td>{{ item.tippcsfol }}</td>
                    <td>{{ item.stafol }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <uspSkeleton v-show="carregandoFolha">
                <div>
                    <div class="w-45"></div>
                    <div class="w-55"></div>
                </div>     
            </uspSkeleton>
          </form>
        </template>
      </uspCartao>
      <uspCartao :titulo="folhaSelecionada + ' - Processamentos'" v-if="folhaSelecionada" :fechado="fechado['processamentos']">
        <template slot="corpo">
          <form>
            <div class="form-row table-responsive">
              <table class="table" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 5%;">Seq.</th>
                    <th scope="col" style="width: 10%;">
                        <select class="form-control form-select-sm" v-model="filtroProc">
                            <option value="T">Todos</option>
                            <option value="R">Reais</option>
                            <option value="S">Simula&ccedil;&otilde;es</option>
                        </select>
                    </th>
                    <th scope="col" style="width: 10%;">Data de cria&ccedil;&atilde;o</th>
                    <th scope="col" style="width: 15%;">Respons&aacute;vel</th>
                    <th scope="col" style="width: 10%;">Data de fechamento</th>                    
                    <th scope="col" style="width: 10%;">Mensagens</th>  
                    <th scope="col" style="width: 20%;">Observa&ccedil;&atilde;o</th>                  
                    <th scope="col" style="width: 10%;">Situa&ccedil;&atilde;o</th>      
                    <th scope="col" style="width: 10%;" class="text-right">
                        <div class="spinner-border spinner-border-sm" role="status" v-show="carregandoProcs">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </th>                    
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in listaProcessamentoFiltrada"
                    :key="item.numseqpcsfol"
                    :class="comparacao[0].codfolpag == item.codfolpag && comparacao[0].numseqpcsfol == item.numseqpcsfol ? 'text-primary font-weight-bold' : (comparacao[1].codfolpag == item.codfolpag && comparacao[1].numseqpcsfol == item.numseqpcsfol ? 'text-secondary font-weight-bold' : '')"
                  >
                    <td>{{ item.numseqpcsfol }}</td>
                    <td>{{ item.tippcs == 'R' ? 'Real' : (item.tippcs == 'S' ? 'Simulação' : (item.tippcs == 'L' ? 'Legado' : (item.tippcs == 'M' ? 'Margem' : (item.tippcs == 'I' ? "Integral" : "")))) }}</td>
                    <td>{{ item.dtacad }}</td>
                    <td>{{ item.nompes }}</td>
                    <td>{{ item.dtafchfol }}</td>                    
                    <td>
                      <span v-show="item.numseqpcsfol != 0 && item.tippcs != 'M' && item.tippcs != 'I'">
                        <span v-if="item.erros > 0" title="Erros">
                          <a href="#" @click="exibirMensagens(item, 'E')"><i class="fas fa-bug"></i>&nbsp;{{ item.erros }}</a>
                        </span>
                        <span v-else>
                          <i class="fas fa-bug"></i>&nbsp;{{ item.erros }}
                        </span>
                        <span v-if="item.alertas > 0">
                          <a href="#" @click="exibirMensagens(item, 'A')"><i class="fas fa-exclamation-triangle ml-2"></i>&nbsp;{{ item.alertas }}</a>
                        </span>
                        <span v-else>
                          <i class="fas fa-exclamation-triangle ml-2"></i>&nbsp;{{ item.alertas }}
                        </span>
                        <span v-if="item.info > 0">
                          <a href="#" @click="exibirMensagens(item, 'I')"><i class="fas fa-info-circle ml-2"></i>&nbsp;{{ item.info }}</a>
                        </span>
                        <span v-else>
                          <i class="fas fa-info-circle ml-2"></i>&nbsp;{{ item.info }}
                        </span>
                      </span>
                    </td> 
                    <td>{{ item.obspcs }}</td>   
                    <td>{{ item.stapcs }}</td>               
                    <td class="text-right">
                      <a v-if="(comparacao[0].codfolpag == item.codfolpag && comparacao[0].numseqpcsfol == item.numseqpcsfol) || (comparacao[1].codfolpag == item.codfolpag && comparacao[1].numseqpcsfol == item.numseqpcsfol)" href="#" class="mr-2" title="Retirar da comparação" @click.prevent="descomparar(item)">
                        <i class="fas fa-undo"></i>
                      </a>
                      <a v-else href="#" class="mr-2" title="Comparar" @click.prevent="comparar(item)">
                        <i class="fas fa-not-equal"></i>
                      </a>
                      <a
                        href="#"
                        class="mr-2"
                        title="Detalhes"
                        v-show="item.tippcs != 'L' && item.tippcs != 'M' && item.tippcs != 'I'"
                        @click.prevent="editar(item)"
                        data-toggle="modal"
                        data-target="#detalhes"
                      >
                        <i class="fas fa-edit"></i>
                      </a>
                      <router-link title="Análise" v-show="item.tippcs != 'L' && item.tippcs != 'M' && item.tippcs != 'I'" :to="{path: '/novaFolha/analise', query: {codfolpag: item.codfolpag, numseqpcsfol: item.numseqpcsfol}}" target="_blank" class="mr-2">
                        <i class="fas fa-info-circle"></i>
                      </router-link>
                      <!--<a
                        href="#"
                        class="mr-2"
                        title="Logs"
                        v-show="item.tippcs != 'L'"
                        @click.prevent="mostrarLogs(item)"
                        data-toggle="modal"
                        data-target="#logs"
                      >
                        <i class="fas fa-sticky-note"></i>
                      </a>-->
                      <a
                        href="#"
                        class="mr-2"
                        title="Duplicar"
                        v-show="item.tippcs == 'R'"
                        @click.prevent="duplicar(item)"
                      >
                        <i class="fas fa-clone"></i>
                      </a>
                      <a
                        href="#"
                        class="mr-2 text-danger"
                        title="Excluir"
                        v-show="podeExecutar && item.tippcs != 'L' && item.tippcs != 'M' && item.tippcs != 'I' && item.stapcs == ''"
                        @click.prevent="excluirProc(item)"
                      >
                        <i :class="'fas ' + (item.processando ? 'fa-sync' : 'fa-trash')"></i>
                      </a>
                    </td>                     
                  </tr>
                </tbody>
              </table>
            </div>
            <uspSkeleton class="mb-4" v-show="carregandoProcs">
                <div>
                    <div class="w-50"></div>
                    <div class="w-50"></div>
                </div>     
            </uspSkeleton>
            <div class="form-row mt-2" v-if="podeExecutar">
              <div class="form-group mb-0 col-sm-12 text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="novoProc()"
                >Novo processamento</button>
              </div>
            </div>
          </form>
        </template>
      </uspCartao>
      <div
        class="card-group mt-3"
        style="margin-bottom: 32px;"
        v-if="comparacao[0].codfolpag != '' || comparacao[1].codfolpag != ''"
      >
        <uspCartao
          :titulo="item.codfolpag + ' / ' + item.numseqpcsfol"
          :key="item.codfolpag + '|' + item.numseqpcsfol"
          v-for="(item, index) in comparacao"
          v-show="item.codfolpag"
          :fechado="fechado['comparacao' + index]"
          :removivel="true"
          @remover="descomparar(comparacao[index])"
          :class="index == 0 && comparacao[1].codfolpag != '' ? 'mr-0 mr-sm-4': ''"
        >
          <template slot="corpo">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" :style="index == 0 ? 'width: 80%;' : 'width: 60%;'">Item</th>
                    <th scope="col" style="width: 20%;" class="text-right">
                        Valor
                        <div class="spinner-border spinner-border-sm ml-2" role="status" v-show="carregandoComparacao[index]">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </th>
                    <th v-if="index == 1" scope="col" style="width: 20%;"></th>
                  </tr>
                </thead>
                <tbody v-show="!carregandoComparacao[index]">
                  <tr>
                    <td>Servidores</td>
                    <td class="text-right">{{ item.rubs[-1] ? parseInt(item.rubs[-1]) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[-1] 
                                && (comparacao[1].rubs[-1] - comparacao[0].rubs[-1]) > 0 
                                && (comparacao[1].rubs[-1] - comparacao[0].rubs[-1]) < 10">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[-1] - comparacao[0].rubs[-1])) }}
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[-1] 
                                && (comparacao[1].rubs[-1] - comparacao[0].rubs[-1]) >= 10">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[-1] - comparacao[0].rubs[-1])) }}
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[-1] 
                                && (comparacao[1].rubs[-1] - comparacao[0].rubs[-1]) < 0 
                                && (comparacao[1].rubs[-1] - comparacao[0].rubs[-1]) > -10">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[-1] - comparacao[0].rubs[-1])) }}
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[-1] 
                                && (comparacao[1].rubs[-1] - comparacao[0].rubs[-1]) <= -10">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[-1] - comparacao[0].rubs[-1])) }}
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total de vantagens (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[99] ? Number(item.rubs[99]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[99] 
                                && (comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99] > 0 
                                && (comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[99] 
                                && (comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[99] 
                                && (comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99] < 0 
                                && (comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[99] 
                                && (comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[99] - comparacao[0].rubs[99]) * 100.0 / comparacao[0].rubs[99]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total l&iacute;quido (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[98] ? Number(item.rubs[98]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[98] 
                                && (comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98] > 0 
                                && (comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[98] 
                                && (comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[98] 
                                && (comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98] < 0 
                                && (comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[98] 
                                && (comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[98] - comparacao[0].rubs[98]) * 100.0 / comparacao[0].rubs[98]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total de descontos (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[287] ? Number(item.rubs[287]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[287] 
                                && (comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287] > 0 
                                && (comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[287] 
                                && (comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[287] 
                                && (comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287] < 0 
                                && (comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[287] 
                                && (comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[287] - comparacao[0].rubs[287]) * 100.0 / comparacao[0].rubs[287]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Obriga&ccedil;&atilde;o patr. previd. (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[351] ? Number(item.rubs[351]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[351] 
                                && (comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351] > 0 
                                && (comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[351] 
                                && (comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[351] 
                                && (comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351] < 0 
                                && (comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[351] 
                                && (comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[351] - comparacao[0].rubs[351]) * 100.0 / comparacao[0].rubs[351]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Fgts (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[286] ? Number(item.rubs[286]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[286] 
                                && (comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286] > 0 
                                && (comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[286] 
                                && (comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[286] 
                                && (comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286] < 0 
                                && (comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[286] 
                                && (comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[286] - comparacao[0].rubs[286]) * 100.0 / comparacao[0].rubs[286]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Seguro acidente de trab. (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[329] ? Number(item.rubs[329]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[329] 
                                && (comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329] > 0 
                                && (comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[329] 
                                && (comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[329] 
                                && (comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329] < 0 
                                && (comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[329] 
                                && (comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[329] - comparacao[0].rubs[329]) * 100.0 / comparacao[0].rubs[329]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Previd. compl. patroc. (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[530] ? Number(item.rubs[530]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[530] 
                                && (comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530] > 0 
                                && (comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[530] 
                                && (comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[530] 
                                && (comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530] < 0 
                                && (comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[530] 
                                && (comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[530] - comparacao[0].rubs[530]) * 100.0 / comparacao[0].rubs[530]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Imposto de renda (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[108] ? Number(item.rubs[108]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[108] 
                                && (comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108] > 0 
                                && (comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[108] 
                                && (comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[108] 
                                && (comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108] < 0 
                                && (comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[108] 
                                && (comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[108] - comparacao[0].rubs[108]) * 100.0 / comparacao[0].rubs[108]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Iamspe (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[110] ? Number(item.rubs[110]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[110] 
                                && (comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110] > 0 
                                && (comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[110] 
                                && (comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[110] 
                                && (comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110] < 0 
                                && (comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[110] 
                                && (comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[110] - comparacao[0].rubs[110]) * 100.0 / comparacao[0].rubs[110]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Inss (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[113] ? Number(item.rubs[113]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[113] 
                                && (comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113] > 0 
                                && (comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[113] 
                                && (comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[113] 
                                && (comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113] < 0 
                                && (comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[113] 
                                && (comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[113] - comparacao[0].rubs[113]) * 100.0 / comparacao[0].rubs[113]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Contrib. previd. Spprev (R$)</td>
                    <td
                      class="text-right"
                    >{{ item.rubs[445] ? Number(item.rubs[445]).toLocaleString(undefined, {minimumFractionDigits: 2}) : item.rubs[674] ? Number(item.rubs[674]).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right" v-if="index == 1">
                        <span class="text-primary" v-if="comparacao[0].rubs[674] 
                                && (comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674] > 0 
                                && (comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674] < 1">
                            <i class="fas fa-chevron-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-primary" v-if="comparacao[0].rubs[674] 
                                && (comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674] >= 1">
                            <i class="fas fa-chevron-circle-up"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[674] 
                                && (comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674] < 0 
                                && (comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674] > -1">
                            <i class="fas fa-chevron-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                        <span class="text-danger" v-if="comparacao[0].rubs[674] 
                                && (comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674] <= -1">
                            <i class="fas fa-chevron-circle-down"></i>&nbsp;
                            {{ Number((comparacao[1].rubs[674] - comparacao[0].rubs[674]) * 100.0 / comparacao[0].rubs[674]).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}%
                        </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <uspSkeleton class="mb-4" v-show="carregandoComparacao[index]">
                <div>
                    <div class="w-80 mt-0 mb-1"></div>
                    <div class="w-20 mt-0 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
                <div>
                    <div class="w-80 mb-1"></div>
                    <div class="w-20 mb-1"></div>
                </div>     
            </uspSkeleton>
            <div class="text-center" v-if="index == 0 && item.codfolpag != 20050000 && item.codfolpag != 20130000">
              <a
                :href="'/proxy/folha/servicos/planilha?modo=C&codfolpag1=' + item.codfolpag + '&numseqpcsfol1=' + item.numseqpcsfol + '&codfolpag2=&numseqpcsfol2='"
                target="_blank"
                class="btn btn-primary mr-2"
              >Planilha consolidada</a>
              <a
                :href="'/proxy/folha/servicos/planilha?modo=D&codfolpag1=' + item.codfolpag + '&numseqpcsfol1=' + item.numseqpcsfol + '&codfolpag2=&numseqpcsfol2='"
                target="_blank"
                class="btn btn-secondary"
              >Detalhada</a>
            </div>
            <div class="text-center" v-if="index == 1 && item.codfolpag != 20050000 && item.codfolpag != 20130000">
              <a
                :href="'/proxy/folha/servicos/planilha?modo=C&codfolpag1=' + comparacao[0].codfolpag + '&numseqpcsfol1=' + comparacao[0].numseqpcsfol + '&codfolpag2=' + comparacao[1].codfolpag + '&numseqpcsfol2=' + comparacao[1].numseqpcsfol"
                target="_blank"
                class="btn btn-primary mr-2"
              >Compara&ccedil;&atilde;o consol.</a>
              <a
                :href="'/proxy/folha/servicos/planilha?modo=D&codfolpag1=' + comparacao[0].codfolpag + '&numseqpcsfol1=' + comparacao[0].numseqpcsfol + '&codfolpag2=' + comparacao[1].codfolpag + '&numseqpcsfol2=' + comparacao[1].numseqpcsfol"
                target="_blank"
                class="btn btn-secondary"
              >Detalhada</a>
            </div>
          </template>
        </uspCartao>
      </div>
      <uspCartao
        v-if="comparacao[0].codfolpag != '' && comparacao[1].codfolpag != ''"
        :titulo="comparacao[0].codfolpag + ' / ' + comparacao[0].numseqpcsfol + ' x ' + comparacao[1].codfolpag + ' / ' + comparacao[1].numseqpcsfol"
        :fechado="fechado['comparacao']"
      >
        <template slot="corpo">
            <div class="form-row table-responsive" v-show="comparacao[0].codfolpag != 20050000 && comparacao[0].codfolpag != 20130000 && comparacao[1].codfolpag != 20050000 && comparacao[1].codfolpag != 20130000">
                <table class="table">
                    <thead>
                        <th style="width: 16%;">Quantidade de valores</th>
                        <th style="width: 16%;" class="text-primary">Valores iguais</th>
                        <th style="width: 16%;" class="text-secondary">Dentro da toler&acirc;ncia</th>
                        <th style="width: 16%;" class="text-danger">Fora da toler&acirc;ncia</th>
                        <th style="width: 16%;" class="text-primary">Iguais ou <br>dentro da toler&acirc;ncia</th>
                        <th style="width: 16%;" class="text-danger">A mais</th>
                        <th style="width: 4%;" class="text-right">
                            <div class="spinner-border spinner-border-sm" role="status" v-show="carregandoIndice">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </th>
                    </thead>
                    <tbody v-show="indice.sucessos">
                        <tr>
                            <td>{{ indice.totais }}</td>
                            <td class="text-primary">{{ indice.sucessos }}</td>
                            <td class="text-secondary">{{ indice.tolerancias }}</td>
                            <td class="text-danger">{{ indice.erros }}</td>
                            <td class="text-primary">{{ indice.sucessos ? parseInt(indice.sucessos) + parseInt(indice.tolerancias) : "" }}</td>
                            <td class="text-danger">{{ indice.excessos }}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>100 %</td>
                            <td class="text-primary">{{ indice.sucessos ? Number(indice.sucessos * 100.0 / indice.totais).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " %" : "" }}</td>
                            <td class="text-secondary">{{ indice.sucessos ? Number(indice.tolerancias * 100.0 / indice.totais).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " %" : "" }}</td>
                            <td class="text-danger">{{ indice.sucessos ? Number(indice.erros * 100.0 / indice.totais).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " %" : "" }}</td>
                            <td class="text-primary">{{ indice.sucessos ? Number((parseInt(indice.sucessos) + parseInt(indice.tolerancias)) * 100.0 / indice.totais).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " %" : "" }}</td>
                            <td class="text-danger">{{ indice.sucessos ? Number(indice.excessos * 100.0 / indice.totais).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " %" : "" }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <uspSkeleton v-show="carregandoIndice">
                <div>
                    <div class="w-50 mb-4 mt-0"></div>
                    <div class="w-50 mb-4 mt-0"></div>
                </div>     
            </uspSkeleton>
          <div class="form-row mb-3">
            <div class="col-sm-3">
                <select v-model="tipoComparacao" class="form-control" @change="montarDiferencas()">
                    <option value="99">Comparando vantagens</option>
                    <option value="98">Comparando líquido</option>
                </select>
            </div>
            <div class="col-sm-3">
                <select v-model="grupoCond" class="form-control" @change="montarDiferencas()">
                    <option value="">Todos os grupos</option>
                    <option value="1">1 - Celetista INSS</option>
                    <option value="2">2 - Docente IPESP</option>
                    <option value="3">3 - Func Aut IPESP</option>
                    <option value="4">4 - Doc Apos IPESP</option>
                    <option value="5">5 - Func Apos IPESP</option>
                    <option value="6">6 - Compl Apos INSS</option>
                    <option value="7">7 - Estagi&aacute;rio</option>
                    <option value="8">8 - Func Aut INSS</option>
                    <option value="9">9 - Docente INSS</option>
                </select>
            </div>
            <div class="col-sm-3">
                <select v-model="tolerancia" class="form-control" @change="montarDiferencas()">
                    <option value="0">Todas as diferenças</option>
                    <option value="1">Diferenças > R$ 1</option>
                    <option value="10">Diferenças > R$ 10</option>
                    <option value="100">Diferenças > R$ 100</option>
                    <option value="1000">Diferenças > R$ 1.000</option>
                </select>
            </div>
            <div class="col-sm-3 ">
                <div class="form-check mt-2 ml-2">
                    <input class="form-check-input" v-model="altaCupula" @change="montarDiferencas()" value="S" type="checkbox">
                    <label class="form-check-label" for="inlineFormCheck">
                        Casos cr&iacute;ticos
                    </label>
                </div>
            </div>
          </div>
          <form>
            <div class="form-row table-responsive">
              <table class="table" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <!--<th scope="col" style="width: 5%;"></th>-->
                    <th scope="col" style="width: 10%;">N. USP</th>
                    <th scope="col" style="width: 5%;">Seq.</th>
                    <th scope="col" style="width: 5%;">Grupo</th>
                    <th scope="col" style="width: 20%;">Nome</th>
                    <th scope="col" class="text-right" style="width: 10%;">Valor 1</th>
                    <th scope="col" class="text-right" style="width: 10%;">Valor 2</th>
                    <th scope="col" class="text-right" style="width: 10%;">Diferen&ccedil;a</th>
                    <th scope="col" style="width: 5%;" class="text-right">
                        <div class="spinner-border spinner-border-sm" role="status" v-show="carregandoDiff && !(comparacao[0].codfolpag != 20050000 && comparacao[0].codfolpag != 20130000 && comparacao[1].codfolpag != 20050000 && comparacao[1].codfolpag != 20130000)">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </th>
                    <th scope="col" style="width: 20%;" v-if="comparacao[0].codfolpag != 20050000 && comparacao[0].codfolpag != 20130000 && comparacao[1].codfolpag != 20050000 && comparacao[1].codfolpag != 20130000">Observa&ccedil;&atilde;o</th>
                    <th scope="col" class="text-right" style="width: 5%;" v-if="comparacao[0].codfolpag != 20050000 && comparacao[0].codfolpag != 20130000 && comparacao[1].codfolpag != 20050000 && comparacao[1].codfolpag != 20130000">
                        <div class="spinner-border spinner-border-sm" role="status" v-show="carregandoDiff">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in diferencas"
                    :key="item.codpes + '|' + item.numseqsrv + '|' + item.codgrpcon"
                  >
                    <!--<td>
                      <input
                        type="radio"
                        name="diferencaCaso"
                        v-model="diferencaCaso"
                        :value="item.codpes + '/' + item.numseqsrv + '/' + item.codgrpcon"
                      />
                    </td>-->
                    <td>{{ item.codpes }}</td>
                    <td>{{ item.numseqsrv }}</td>
                    <td>{{ item.tipcon }}</td>
                    <td>{{ item.nompes }}</td>
                    <td class="text-right">{{ item.vlrpag1 ? Number(item.vlrpag1).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right">{{ item.vlrpag2 ? Number(item.vlrpag2).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td class="text-right">{{ item.diff ? Number(item.diff).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td><a href="#" title="Comparar" @click.prevent="listarComparacao(item)">
                      <i class="fas fa-not-equal"></i>
                    </a></td>
                    <td v-if="comparacao[0].codfolpag != 20050000 && comparacao[0].codfolpag != 20130000 && comparacao[1].codfolpag != 20050000 && comparacao[1].codfolpag != 20130000">{{ item.obsfolsrv }}</td>
                    <td v-if="comparacao[0].codfolpag != 20050000 && comparacao[0].codfolpag != 20130000 && comparacao[1].codfolpag != 20050000 && comparacao[1].codfolpag != 20130000">
                        <a
                            href="#"
                            title="Observação"
                            @click.prevent="mostrarObsServidor(item)"
                            data-toggle="modal"
                            data-target="#obsServidor"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <uspSkeleton v-show="carregandoDiff">
                <div>
                    <div class="w-50"></div>
                    <div class="w-50"></div>
                </div>     
            </uspSkeleton>
          </form>
        </template>
      </uspCartao>
      <!--<uspCartao
        v-if="comparacao[0].codfolpag != '' && comparacao[1].codfolpag != '' && diferencaCaso != ''"
        :titulo="diferencaCaso + ' - ' + diferencaNome"
        :fechado="fechado['diferencas']"
      >
        <template slot="corpo">
          <form>
            <div class="form-row table-responsive">
              <table class="table" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 10%;">Rubrica</th>
                    <th scope="col" style="width: 60%;">Nome</th>
                    <th scope="col" class="text-right" style="width: 10%;">Valor 1</th>
                    <th scope="col" class="text-right" style="width: 10%;">Valor 2</th>
                    <th scope="col" class="text-right" style="width: 10%;">Diferen&ccedil;a</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in diferencasCasos" :key="item.codrub">
                    <td>{{ item.codrub }}</td>
                    <td>{{ item.nomrub }}</td>
                    <td class="text-right">{{ item.vlrpag1 }}</td>
                    <td class="text-right">{{ item.vlrpag2 }}</td>
                    <td class="text-right">{{ item.diff }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </template>
      </uspCartao>-->
      <uspCartao v-if="novoProcessamento" :titulo="folhaSelecionada + ' - Novo'" :fechado="fechado['novo']">
        <template slot="corpo">
          <form>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Tipo</label>
                <select
                  v-model="parametros.tipo"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                >
                  <option value></option>
                  <option value="R">Real</option>
                  <option value="S">Simula&ccedil;&atilde;o com dados carregados</option>
                  <option value="M">Simula&ccedil;&atilde;o com base no marteweb</option>
                </select>
              </div>
              <div class="form-group col-sm-6" v-show="parametros.tipo == 'S'">
                <label>Data de fechamento</label>
                <input
                  type="datetime-local"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.dtafchfol"
                />
              </div>
              <div class="form-group col-sm-6" v-show="parametros.tipo == 'R'">
                <label>Data de fechamento</label>
                <select class="form-control" v-model="parametros.dtafchfol" :disabled="estado != 'parametros'">
                  <option value=""></option>
                  <option v-for="item in ultimasCargas" :value="item.dtahorfimcga">{{ item.dtahorfimcgafmt }}</option>
                </select>
              </div>
            </div>
            <div class="form-row table-responsive" v-if="parametros.tipo == 'R'">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%;">N&uacute;mero USP</th>
                    <th scope="col" style="width: 55%;">Nome</th>
                    <th scope="col" style="width: 20%;">Data de fechamento (exce&ccedil;&atilde;o)</th>
                    <th scope="col" style="width: 5%;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parametros.excecoes" :key="item.codpes">
                    <td>{{ item.codpes }}</td>
                    <td>{{ item.nompes }}</td>
                    <td>{{ item.dtafchfolecc }}</td>
                    <td>
                      <a href="#" @click.prevent="excluirExcecao(item)">
                        <i class="fas fa-minus"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="(codpesNaoEncontrados.length != 0 || excecoesRepetidas.length != 0)">
                    <td colspan="4">
                      <usp-alerta 
                        variante="perigo"
                        :fechavel="true"
                        @fechar="codpesNaoEncontrados = []; excecoesRepetidas = []; stringNompes =''"
                      >
                        <p>Atenção!</p>
                        <div v-if="codpesNaoEncontrados.length != 0">
                          <p>Os seguintes números USP não foram encontrados:</p>
                          <ul>
                            <li v-for="item in codpesNaoEncontrados" :key="item.codpes"> {{ item.codpes }}</li>
                          </ul>
                        </div>
                        <div v-if="excecoesRepetidas.length != 0">
                          <p>Os seguintes números USP estão duplicados:</p>
                          <ul>
                            <li v-for="item in excecoesRepetidas" :key="item.codpes"> {{ item.codpes }}</li>
                          </ul>
                        </div>
                      </usp-alerta>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <textarea
                        rows="3"
                        cols="200"
                        v-model="multiplosCodpes"
                        @focus="stringNompes = ''"
                        @blur="atualizarNome()"
                        class="form-control"
                      />
                    </td>
                    <td v-if="carregandoNompes">
                      <uspSkeleton>
                        <div>
                          <div class="w-100" />
                        </div>
                      </uspSkeleton>
                    </td>
                    <td v-else style="white-space:pre-wrap">{{ stringNompes }}</td>
                    <td>
                      <select class="form-control" v-model="novaExcecao.dtafchfolecc" :disabled="estado != 'parametros'">
                        <option value=""></option>
                        <option v-for="item in ultimasCargas" :value="item.dtahorfimcga">{{ item.dtahorfimcgafmt }}</option>
                      </select>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn text-primary"
                        :disabled="!stringNompes || !novaExcecao.dtafchfolecc || codpesNaoEncontrados.length != 0 || excecoesRepetidas.length != 0"
                        @click.prevent="incluirExcecao()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-row" v-if="parametros.tipo == 'S' || parametros.tipo == 'M'">
              <div class="form-group col-sm-4">
                <label>Escopo</label>
                <select
                  class="form-control"
                  :disabled="estado != 'parametros'"
                  v-model="parametros.s_escopo"
                >
                  <option value>USP</option>
                  <option value="I">Individual</option>
                </select>
              </div>
              <div class="form-group col-sm-4" v-if="parametros.s_escopo == 'I'">
                <label>N&uacute;mero USP</label>
                <input
                  type="number"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  maxlength="8"
                  v-model="parametros.s_codpes"
                />
              </div>
              <div class="form-group col-sm-4" v-if="parametros.s_escopo == 'I'">
                <label>Sequencial</label>
                <input
                  type="number"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  maxlength="3"
                  v-model="parametros.s_numseqsrv"
                />
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && parametros.s_escopo != 'I'">
              <div class="form-group col-md-12">
                <label>Grupo de condi&ccedil;&atilde;o</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[1]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">1 - CLT	INSS</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[2]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">2 - Doc IPESP</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[3]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">3 - Func Aut IPESP</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[4]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">4 - Doc Apos IPESP</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[5]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">5 - Func Apos IPESP</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[6]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">6 - Compl Apos INSS</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[7]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">7 - Estag</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[8]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">8 - Func Aut INSS</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" v-model="parametros.s_gc[9]" class="form-check-input" :disabled="estado != 'parametros'" />
                    <label class="form-check-label">9 - Doc INSS</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && parametros.s_escopo != 'I'">
              <div class="form-group col-sm-12">
                <label>Unidade</label>
                <select v-model="parametros.s_codund" class="form-control" :disabled="estado != 'parametros'">
                  <option value="">Todas</option>
                  <option v-for="item in unidades" :key="item.codund" :value="item.codund">{{ item.codund }} - {{ item.sglund }}</option>
                </select>
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && parametros.s_escopo != 'I'">
              <div class="form-group col-sm-12">
                <label>Eleitos (números USP separados por vírgula)</label>
                <input type="text" v-model="parametros.s_eleitos" class="form-control" :disabled="estado != 'parametros'" />
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M')">
              <div class="form-group col-sm-4">
                <label>Altera&ccedil;&atilde;o</label>
                <select
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_id"
                >
                  <option value>Sem altera&ccedil;&atilde;o</option>
                  <option :value="item.id" :key="item.id" v-for="item in simulacoes">
                    {{
                    item.nome }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-8">
                <label v-show="parametros.s_id != ''">Detalhe</label>
                <p>
                  {{
                  simulacao.descricao }}
                </p>
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && (parametros.s_id == 1 || parametros.s_id == 'AJUSTE_VERBA_REPRES')">
              <div class="form-group col-sm-6">
                <label>Porcentual (%)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_porcentual"
                />
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && parametros.s_id == 'VALOR_TETO'">
              <div class="form-group col-sm-6">
                <label>Teto de reajuste (R$)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_teto_reajuste"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Teto do governador (R$)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_teto_governador"
                />
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && parametros.s_id == 'VALOR_TETO'">
              <div class="form-group col-sm-6">
                <label>Teto do desembargador (R$)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_teto_desembargador"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Teto do ministro (R$)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_teto_ministro"
                />
              </div>
            </div>
            <div class="form-row" v-if="(parametros.tipo == 'S' || parametros.tipo == 'M') && parametros.s_id == 'AUX_CRECHE'">
              <div class="form-group col-sm-6">
                <label>Novo valor para auxílio creche (R$)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_aux_creche"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Novo valor para educação especial (R$)</label>
                <input
                  type="number"
                  step="0.01"
                  :disabled="estado != 'parametros'"
                  class="form-control"
                  v-model="parametros.s_educacao_esp"
                />
              </div>
            </div>
            <div class="form-row" v-if="podeExecutar">
              <div class="form-group col-sm-12 text-center mb-0">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="estado != 'parametros'"
                  @click.prevent="real()"
                >Processar</button>
              </div>
            </div>
          </form>
        </template>
      </uspCartao>
      <uspCartao :titulo="folhaSelecionada + ' - Detalhe'" v-if="novoProcessamento && estado != 'parametros'" :fechado="fechado['detalhe']">
        <template slot="corpo">
          <div class="form-row" v-if="console != ''">
            <div class="form-group col-sm-12">
              <pre id="console" class="mb-0" style="height: 400px; overflow-y: scroll; white-space: pre-wrap;">{{ console }}</pre>
            </div>
          </div>
          <div class="form-row" v-show="estado == 'executando'">
            <div class="form-group col-sm-12 mb-0">
              <div class="progress" style="height: 20px;">
                <div class="progress-bar" role="progressbar" :style="'width: ' + progresso + '%'"></div>
              </div>
            </div>
          </div>
          <div class="form-row table-responsive" v-if="resumo.resumoPagamento && resumo.resumoPagamento.length > 0">
            <table class="table mt-3 mb-0">
              <thead>
                <tr>
                  <th scope="col" style="width: 15%;">Rubrica</th>
                  <th scope="col">Pagamento</th>
                  <th scope="col">Quantidade</th>
                  <th scope="col" class="text-right">Valor (R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in resumo.resumoPagamento" :key="item.codrub">
                  <td>{{ item.codrub }}</td>
                  <td>{{ item.nomrub }}</td>
                  <td>{{ item.qtdrub }}</td>
                  <td class="text-right">{{ item.vlrpag ? Number(item.vlrpag).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row table-responsive" v-if="resumo.resumoMovimento && resumo.resumoMovimento.length > 0">
            <table class="table mt-3 mb-0">
              <thead>
                <tr>
                  <th scope="col" style="width: 15%;">Rubrica</th>
                  <th scope="col">Movimento</th>
                  <th scope="col">Quantidade</th>
                  <th scope="col" class="text-right">Valor (R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in resumo.resumoMovimento" :key="item.codrub">
                  <td>{{ item.codmov }}</td>
                  <td>{{ item.nommov }}</td>
                  <td>{{ item.qtdmov }}</td>
                  <td class="text-right">{{ item.vlrmov ? Number(item.vlrmov).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <uspModal :titulo="tituloDet" ref="ProcDet" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
        <template slot="body">
          <div class="row">
            <div class="form-group col-sm-12">
              <pre style="height: 150px; overflow-y: scroll; white-space: pre-wrap;" v-html="procEdicao.detalhes"></pre>
            </div>
          </div>
          <div>
            <div class="form-group col-sm-12">
              <textarea v-model="procEdicao.obspcs" placeholder="Observação" class="form-control" style="height: 100px;"></textarea>
            </div>
          </div>
          <pre style="height: 400px; overflow-y: scroll; white-space: pre-wrap;">{{ logs }}</pre>
          <uspAlerta variante="perigo" v-show="!!erroEditar" class="mb-0" fechavel>
            {{ erroEditar }}
          </uspAlerta>
        </template>
        <template slot="footer">
            <!--<button type="button" class="btn btn-danger mr-5" :disabled="desabilitarConsolidar" data-dismiss="modal" @click="consolidar()">Consolidar</button>-->
            <button type="button" class="btn btn-secondary" @click="fecharModal('ProcDet')">Fechar</button>
            <button type="button" class="btn btn-primary" :disabled="desabilitarSalvarDetalhe" data-dismiss="modal" @click="alterarProc()">Salvar</button>
        </template>
      </uspModal>
      <uspModal :titulo="tituloObsServidor" ref="obsServidor" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
        <template slot="body">
          <div>
            <div class="form-group col-sm-12">
              <textarea v-model="obsfolsrv" placeholder="Observação" class="form-control" style="height: 200px;"></textarea>
            </div>
          </div>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-primary" :disabled="desabilitarSalvarObsServidor" @click="salvarObsServidor()">Salvar</button>
        </template>
      </uspModal>
      <uspModal :titulo="tituloLogs" ref="ProcLogs" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
        <template slot="body">
            <pre style="height: 400px; overflow-y: scroll; white-space: pre-wrap;">{{ logs }}</pre>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" @click="fecharModal('ProcLogs')">Fechar</button>
        </template>
      </uspModal>
      <uspModal :titulo="tituloMensagem" ref="mensagens" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
          <template slot="body">
            <table class="table mt-3 mb-0">
              <thead>
                <tr>
                  <th scope="col">N. USP</th>
                  <th scope="col">Seq.</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Mensagem(ns)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaMensagem" v-bind:key="item.codpes + '|' + item.numseqsrv + '|' + item.codgrpcon">
                  <td>{{ item.codpes }}</td>
                  <td>{{ item.numseqsrv }}</td>
                  <td>{{ item.codgrpcon }}</td>
                  <td>{{ item.nompes }}</td>
                  <td>{{ item.msgpcs }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="footer">
              <button type="button" class="btn btn-secondary" @click="fecharModal('mensagens')">Fechar</button>
          </template>
      </uspModal>
      <uspModal :titulo="tituloComparacao" ref="comparacao" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
          <template slot="body">
            <select class="form-control" v-model="comparacaoAux" v-if="(comparacao[0].codfolpag.endsWith('03') || comparacao[0].codfolpag.endsWith('15')) && (comparacao[1].codfolpag.endsWith('03') || comparacao[1].codfolpag.endsWith('15'))">
                <option value=""></option>
                <option value="N">Normal simulada</option>
            </select>
            <table class="table table-responsive mt-3 mb-0">
              <thead>
                <tr>
                  <th scope="col" style="width: 15%;">Rubrica</th>
                  <th scope="col">Nome</th>
                  <th scope="col" class="text-right">Valor 1 (R$)</th>
                  <th scope="col" class="text-right">Valor 2 (R$)</th>
                  <th scope="col" class="text-right">Diferença (R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaComparacao" :key="item.codrub">
                  <td>{{ item.codrub }}</td>
                  <td>{{ item.nomrub }}</td>
                  <td class="text-right">{{ item.vlrpag1 && item.vlrpag1 != 0 ? Number(item.vlrpag1).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                  <td class="text-right">{{ item.vlrpag2 && item.vlrpag2 != 0 ? Number(item.vlrpag2).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                  <td class="text-right">{{ item.diff && item.diff != 0 ? Number(item.diff).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="footer">
              <button type="button" class="btn btn-secondary" @click="fecharModal('comparacao')">Fechar</button>
          </template>
      </uspModal>
    </div>
  </section>
</template>
<script>
import NovaFolhaServico from "../dominio/novaFolha/NovaFolhaServico";
const api = NovaFolhaServico.build({});

export default {
  name: "Processamento",
  computed: {
    tituloDet() {
      return `${this.procEdicao.codfolpag}/${this.procEdicao.numseqpcsfol} - Detalhes`;
    },
    tituloLogs() {
      return `${this.procEdicao.codfolpag}/${this.procEdicao.numseqpcsfol} - Logs`;
    },
    listaProcessamentoFiltrada() {
        var self = this;
        var lista = [];
        for (var i in self.processamentos) {
            if (self.filtroProc == "T" || self.processamentos[i].tippcs == self.filtroProc || (self.processamentos[i].tippcs == 'L' && self.filtroProc == "R")) {
                lista.push(self.processamentos[i]);
            }
        }
        return lista;
    }
  },
  data() {
    return {
      filtroProc: "R",
      podeExecutar: false,
      folhaNaoEncontrada: false,
      tiposFolha: [],
      tiposProcessamento: [],
      folhaSelecionada: "",
      folha: {
        ano: "",
        mes: "",
        codtipfol: "",
        tippcsfol: ""
      },
      fechado: {
        'folhas': false,
        'processamentos': false,
        'comparacao0': false,
        'comparacao1': false,
        'comparacao': false,
        'diferencas': false,
        'novo': false,
        'detalhe': false
      },
      folhas: [],
      novoProcessamento: false,
      processamentos: [],
      procEdicao: {},
      comparacao: [
        { codfolpag: "", numseqpcsfol: "", tippcs: "", rubs: [] },
        { codfolpag: "", numseqpcsfol: "", tippcs: "", rubs: [] }
      ],
      diferencas: [],
      diferencaCaso: "",
      tituloComparacao: "", 
      parametros: {
        s_id: "",
        s_escopo: "",
        excecoes: [],
        s_gc: [true, true, true, true, true, true, true, true, true, true]
      },
      simulacao: {},
      simulacoes: [],
      unidades: [],
      logs: "",
      listaComparacao: [],
      comparacaoAux: "",
      comparacaoServidor: {},
      desabilitarConsolidar: false,
      erroEditar: "",
      tipoComparacao: "99",
      carregandoFolha: false,
      carregandoComparacao: [false, false],
      carregandoIndice: false,
      carregandoDiff: false,
      console: "",
      indice: {},
      resumo: [],
      listaAno: [],
      progresso: 0,
      listaMensagem: [],
      tituloMensagem: "",
      desabilitarSalvarDetalhe: false,
      tituloObsServidor: "",
      desabilitarSalvarObsServidor: false,
      obsfolsrv: "",
      obsServidor: {},
      tolerancia: 100,
      grupoCond: "",
      altaCupula: "",
      carregandoProcs: false,
      estado: "parametros", // parametros, executando
      // Variáveis do cartão Novo Processamento
      carregandoNompes: false,
      codpesNaoEncontrados: [],
      excecoesRepetidas: [],
      multiplosCodpes: '',
      novaExcecao: {},
      stringNompes: "",
      windowWidth: window.innerWidth,
      ultimasCargas: [],
      reprocessarOk: false
    };
  },
  watch: {
    estado: function() {
      var self = this;
      if (self.estado == "parametros") {
        self.console = "";
        self.resumo = {};
      } else if (self.estado == "executando") {
        self.random = Math.random() + "";
      }
    },
    "folha.ano": function() {
      this.listarFolha();
    },
    "folha.mes": function() {
      this.listarFolha();
    },
    "folha.codtipfol": function() {
      this.listarFolha();
    },
    "folha.tippcsfol": function() {
      this.listarFolha();
    },
    'comparacaoAux': function() {
        var self = this;
        self.listaComparacao = [];
        self.$forceUpdate();
        api.listarComparacao({'aux': self.comparacaoAux, 'codfolpag1': self.comparacao[0].codfolpag, 'numseqpcsfol1': self.comparacao[0].numseqpcsfol, 'codfolpag2': self.comparacao[1].codfolpag, 'numseqpcsfol2': self.comparacao[1].numseqpcsfol, 'codpes': self.comparacaoServidor.codpes, 'numseqsrv': self.comparacaoServidor.numseqsrv, 'codgrpcon': self.comparacaoServidor.codgrpcon}).then(function(lista) {
            self.listaComparacao = lista;
        });
    },
    folhaSelecionada: function() {
      var self = this;      
      if (self.folhaSelecionada != "") {
        self.fechado['folhas'] = true;
        self.listarProc();        
      } else {
        self.processamentos = [];
        self.novoProcessamento = false;        
      }
    },
    "parametros.s_id": function() {
      var self = this;
      if (self.parametros.s_id != "") {
        for (var i in self.simulacoes) {
          if (self.simulacoes[i].id == self.parametros.s_id) {
            self.simulacao = self.simulacoes[i];
          }
        }
      } else {
        self.simulacao = {};
      }
    },
    diferencaCaso: function() {
      var self = this;
      self.diferencasCasos = [];
      self.diferencaNome = "";
      if (self.diferencaCaso != "") {
        api
          .listarDiffCaso({
            codpes: self.diferencaCaso.split("/")[0],
            numseqsrv: self.diferencaCaso.split("/")[1],
            codgrpcon: self.diferencaCaso.split("/")[2],
            codfolpag1: self.comparacao[0].codfolpag,
            numseqpcsfol1: self.comparacao[0].numseqpcsfol,
            codfolpag2: self.comparacao[1].codfolpag,
            numseqpcsfol2: self.comparacao[1].numseqpcsfol,
            tolerancia: self.tolerancia
          })
          .then(function(resposta) {
            self.diferencasCasos = resposta;
          });
        for (var i in self.diferencas) {
          if (
            self.diferencaCaso ==
            self.diferencas[i].codpes +
              "/" +
              self.diferencas[i].numseqsrv +
              "/" +
              self.diferencas[i].codgrpcon
          ) {
            self.diferencaNome = self.diferencas[i].nompes;
          }
        }
      }
    }
  },
  methods: {
    salvarObsServidor: function() {
      var self = this;
      self.desabilitarSalvarObsServidor = true;
      api.salvarObsServidor({
        codpes: self.obsServidor.codpes,
        numseqsrv: self.obsServidor.numseqsrv,
        codgrpcon: self.obsServidor.codgrpcon,
        codfolpag1: self.comparacao[0].codfolpag,
        numseqpcsfol1: self.comparacao[0].numseqpcsfol,
        codfolpag2: self.comparacao[1].codfolpag,
        numseqpcsfol2: self.comparacao[1].numseqpcsfol,
        obsfolsrv: self.obsfolsrv
      }).then(function() {
            self.desabilitarSalvarObsServidor = false;
            self.$refs['obsServidor'].closeModal();
            self.montarDiferencas();
      });
    },
    alterarProc: function() {
      var self = this;
      self.desabilitarSalvarDetalhe = true;
      api.alterarProc(self.procEdicao).then(function() {
          self.desabilitarSalvarDetalhe = false;
        self.$refs['ProcDet'].closeModal();
        self.listarProc();
      });
    },
    atualizarNome: function() {
      const self = this;

      // Limpa as variáveis
      self.stringNompes = "";
      self.codpesNaoEncontrados = [];
      self.excecoesRepetidas = [];

      if (!self.multiplosCodpes) {
        return;
      }
      
                    // Substitui tudo que não for dígito por ";" depois separa os codpes por ";"
      const codpesLote = self.multiplosCodpes.replaceAll(/[^\d]+/g, ";").split(/[;]+/)
                          // Filtra os codpes repetidos ou vazios
                          .filter((codpes, pos, self) => codpes && self.indexOf(codpes) == pos)
                          .map(codpes => ({ codpes }));

      if (codpesLote.length == 0) {
        return;
      }

      self.carregandoNompes = true;
      
      self.novaExcecao.nompesCodpes = [];
      api
        .obterNomePessoaLote(codpesLote)
        .then(nompesCodpes => {
          // Joga o retorno da api em uma variável
          self.novaExcecao.nompesCodpes = nompesCodpes;
          
          // Pega o retorno da api e monta uma string com os nompes
          self.stringNompes = self.novaExcecao.nompesCodpes.map(pes => pes.nompes).join("\n");

          // Verifica quais codpes foram digitados pelo usuário (isto é, estão na variável codpesLote) mas não estão no array retornado pela 
          // api (ou seja, não estão em nompesCodpes), o que significa que eles são "inválidos"
          self.codpesNaoEncontrados = codpesLote.filter(({ codpes }) => nompesCodpes.findIndex(pes => pes.codpes == codpes) === -1);

          // Verifica quais codpes já estão adicionados
          self.excecoesRepetidas = self.novaExcecao.nompesCodpes.filter( ({ codpes }) => self.parametros.excecoes.findIndex(pes => pes.codpes == codpes) !== -1);
        })
        .catch(erro => console.log(erro.mensagem) )
        .finally(() => self.carregandoNompes = false );

    },
    real: function() {
      var self = this;
      self.estado = "executando";
      self.parametros.dtafchfol = self.parametros.dtafchfol.replace("T", " ");
      api.processar(self.parametros);
    },
    mesAnterior: function() {
      var self = this;
      self.folha.mes--;
      if (self.folha.mes == 0) {
        self.folha.mes = 12;
        self.folha.ano--;
      }
    },
    mesAtual: function() {
      var self = this;
      self.folha.ano = new Date().getFullYear();
      self.folha.mes = new Date().getMonth() + 1;
    },
    mesPosterior: function() {
      var self = this;
      self.folha.mes++;
      if (self.folha.mes == 13) {
        self.folha.mes = 1;
        self.folha.ano++;
      }
    },
    listarFolha: function() {
      var self = this;
      self.folhaSelecionada = "";
      self.folhas = [];
      self.folhaNaoEncontrada = false;
      self.carregandoFolha = true;
      api.listarFolhas(self.folha).then(function(resposta) {
        self.folhas = resposta;
        if (self.folhas.length == 0) {
          self.folhaNaoEncontrada = true;
        }
        if (self.$route.query) {
          for (let i in self.folhas) {
            if (self.folhas[i].codfolpag == self.$route.query.codfolpag) {
              self.folhaSelecionada = self.$route.query.codfolpag;
            }
          }
        }
        self.carregandoFolha = false;
      });
    },
    listarProc: function() {
      var self = this;
      self.processamentos = [];
      self.carregandoProcs = true;
      api
        .listarProcLegado({ codfolpag: self.folhaSelecionada })
        .then(function(resposta) {
          self.processamentos = resposta;
          api
            .listarProcs({ codfolpag: self.folhaSelecionada })
            .then(function(resposta2) {
              //console.log(resposta2);            
              for (var i in resposta2) {
                self.processamentos.push(resposta2[i]);
              }
              for (var j in self.processamentos) {
                self.processamentos[j].processando = false;
              }
              if (!self.reprocessarOk && self.$route.query.reprocessar) {
                for (var i in self.processamentos) {
                  if (self.processamentos[self.processamentos.length - 1 - i].tippcs == 'R') {
                    self.duplicar(self.processamentos[self.processamentos.length - 1 - i]);
                    break;
                  }
                }
                self.reprocessarOk = true;
              }
            }).finally(function() {
              self.carregandoProcs = false;
            });
        });
    },
    novoProc: function() {
      var self = this;
      self.estado = "parametros";
      self.novoProcessamento = true;
      self.parametros.codfolpag = self.folhaSelecionada;
      self.comparacao[0].codfolpag = "";
      self.comparacao[1].codfolpag = "";
      self.novaExcecao = {};
      self.parametros.excecoes = [];
    },
    duplicar: function(item) {
      var self = this;
      self.novoProc();
      self.parametros.dtafchfol =
        item.dtafchfol.substring(6, 10) +
        "-" +
        item.dtafchfol.substring(3, 5) +
        "-" +
        item.dtafchfol.substring(0, 2) + 
        " " + 
        item.dtafchfol.substring(11, 16);
      console.log(
        item.dtafchfol.substring(6, 10) +
          "-" +
          item.dtafchfol.substring(3, 5) +
          "-" +
          item.dtafchfol.substring(0, 2) + 
        " " + 
        item.dtafchfol.substring(11, 16)
      );
      self.parametros.tipo = item.tippcs;
      api.listarExcecoes(item).then(function(resposta) {
        self.parametros.excecoes = resposta;
      });
    },
    exibirMensagens: function(item, nivmsgpcs) {
      var self = this;
      self.$refs['mensagens'].openModal();
      self.tituloMensagem = item.codfolpag + "/" + item.numseqpcsfol + " - " + (nivmsgpcs == 'E' ? "Erros" : (nivmsgpcs == "A" ? "Alertas" : (nivmsgpcs == "I" ? "Informações" : "")));
      self.listaMensagem = [];
      self.$forceUpdate();
      api.listarMensagem(Object.assign({}, item, {'nivmsgpcs': nivmsgpcs})).then(function(lista) {
        self.listaMensagem = lista;
      });
    },
    listarComparacao: function(item) {
      var self = this;
      self.$refs['comparacao'].openModal();
      self.comparacaoAux = "";
      self.comparacaoServidor = item;
      self.listaComparacao = [];
      self.tituloComparacao = self.comparacao[0].codfolpag + "/" + self.comparacao[0].numseqpcsfol + " x " + self.comparacao[1].codfolpag + "/" + self.comparacao[1].numseqpcsfol + ' - ' + item.codpes + '/' + item.numseqsrv + ' - ' + item.nompes;
      self.$forceUpdate();
      api.listarComparacao({'codfolpag1': self.comparacao[0].codfolpag, 'numseqpcsfol1': self.comparacao[0].numseqpcsfol, 'codfolpag2': self.comparacao[1].codfolpag, 'numseqpcsfol2': self.comparacao[1].numseqpcsfol, 'codpes': item.codpes, 'numseqsrv': item.numseqsrv, 'codgrpcon': item.codgrpcon}).then(function(lista) {
        self.listaComparacao = lista;
      });
    },
    excluirProc: function(item) {
      var self = this;
      if (
        confirm(
          "Esta operação não pode ser revertida! Confirma exclusão deste processamento?"
        )
      ) {
        item.processando = true;
        self.$forceUpdate();
        api
          .excluirProc({
            codfolpag: self.folhaSelecionada,
            numseqpcsfol: item.numseqpcsfol
          })
          .then(function() {
            self.listarProc();
            self.comparacao[0].codfolpag = "";
            self.comparacao[1].codfolpag = "";
            self.novoProcessamento = false;
          });
      }
    },
    montarDiferencas: function() {
      var self = this;
      self.diferencaCaso = "";
      self.diferencas = [];
      self.carregandoDiff = true;
      self.$forceUpdate();
      api
        .listarDiff({
          codfolpag1: self.comparacao[0].codfolpag,
          numseqpcsfol1: self.comparacao[0].numseqpcsfol,
          codfolpag2: self.comparacao[1].codfolpag,
          numseqpcsfol2: self.comparacao[1].numseqpcsfol,
          codrub: self.tipoComparacao,
          tolerancia: self.tolerancia,
          codgrpcon: self.grupoCond,
          alta_cupula: self.altaCupula ? "S" : ""
        })
        .then(function(resposta) {
          self.diferencas = resposta;
          self.carregandoDiff = false;
        })
        .catch(e => {
            alert(e.mensagem);
        });
    },
    fecharModal(ref){
      this.$refs[ref].closeModal();
    },
    mostrarObsServidor: function(item) {
        var self = this;
        self.obsfolsrv = item.obsfolsrv;
        self.$refs['obsServidor'].openModal();
        self.tituloObsServidor = self.comparacao[0].codfolpag + "/" + self.comparacao[0].numseqpcsfol + " x " + self.comparacao[1].codfolpag + "/" + self.comparacao[1].numseqpcsfol + ' - ' + item.codpes + '/' + item.numseqsrv + ' - ' + item.nompes;
        self.obsServidor = item;
    },
    editar: function(item) {
      var self = this;
      self.erroEditar = "";
      self.procEdicao = item;
      self.procEdicao.detalhes = "Executado por: <strong>" + item.nompes + "</strong> em: <strong>" + item.dtacad + "</strong><br/>" + 
      "Data de fechamento: <strong>" + item.dtafchfol + "</strong><br>" +
      "Tipo de processamento: <strong>" + (item.tippcs == "R" ? "Real" : "Simulação") + "</strong><br>";
      if (item.tippcs == "S" && item.tipsmu) {
        for (var i in self.simulacoes) {
          if (self.simulacoes[i].id == item.tipsmu) {
            self.procEdicao.detalhes += "Tipo de simulação: <strong>" + self.simulacoes[i].nome + "</strong><br>";
          }
        }
        var parametros = JSON.parse(item.pmepcssmu);
        for (var parametro in parametros) {
          if (parametro.startsWith("s_") && parametro != 's_id') {
            var descricao = parametro;
            var valor = parametros[parametro];
            if (descricao == "s_escopo") {
              descricao = "Escopo";
              if (valor == "I") valor = "Individual";
              if (valor == "") valor = "USP";
            } else if (descricao == "s_codund") {
              descricao = "Unidade";
            } else if (descricao == "s_eleitos") {
              descricao = "Eleitos";
            } else if (descricao == "s_gc") {
              descricao = "Grupo(s) de condição";
              if (valor.indexOf("false") != -1) {
                var novoValor = "";
                var tokens = valor.split(',');
                for (var j in tokens) {
                  if (tokens[j] == "true" && j != 0) {
                    novoValor += ", " + j;
                  }
                }
                valor = novoValor.substring(2);
              } else {
                descricao = "";
              }
            } else if (descricao == "s_codpes") {
              descricao = "Número USP";
            } else if (descricao == "s_numseqsrv") {
              descricao = "Sequencial";
            } else if (descricao == "s_aux_creche") {
              descricao = "Novo valor para auxílio creche (R$)";
            } else if (descricao == "s_educacao_esp") {
              descricao = "Novo valor para educação especial (R$)";
            } else if (descricao == "s_porcentual") {
              descricao = "Porcentual de reajuste (%)";
            }
            if (descricao != "") {
              self.procEdicao.detalhes += descricao + ": <strong>" + valor + "</strong><br>";
            }
          }
        }
      } else if (item.tippcs == 'R') {
        api.listarExcecoes(item).then(function(excecoes) {
          self.procEdicao.detalhes += "<br>Exceções<br>";
          for (var i in excecoes) {
            self.procEdicao.detalhes += excecoes[i].dtafchfolecc + ": <strong>" + excecoes[i].codpes + "</strong><br>";
          }
        });
      }
      self.$refs['ProcDet'].openModal();
      self.mostrarLogs(item);
    },
    mostrarLogs: function(item) {
      var self = this;
      self.logs = "";
      api.obterLogsProc(item).then(function(resposta) {
        if (resposta.length > 0) {
          self.logs = resposta[0].logmsgpcs;
          //self.$refs['ProcLogs'].openModal();
        }
      });
    },
    descomparar: function(item) {
      var self = this;
      if (self.comparacao[0].codfolpag == item.codfolpag && self.comparacao[0].numseqpcsfol == item.numseqpcsfol) {
        self.comparacao[0].codfolpag = "";
        self.comparacao[0].numseqpcsfol = "";
      } else if (self.comparacao[1].codfolpag == item.codfolpag && self.comparacao[1].numseqpcsfol == item.numseqpcsfol) {
        self.comparacao[1].codfolpag = "";
        self.comparacao[1].numseqpcsfol = "";
      }
    },
    consolidar: function() {
        var self = this;
        self.desabilitarConsolidar = true;
        api.consolidarProc(self.procEdicao).then(function() {
            self.desabilitarConsolidar = false;
            self.$refs['ProcDet'].closeModal();
            self.listarProc();
        }).catch(e => {
            self.desabilitarConsolidar = false;
            self.erroEditar = e.mensagem;
        });
    },
    comparar: function(item) {
      var self = this;
      self.novoProcessamento = false;
      var onde = 0;
      console.log("codfolpag = " + self.comparacao[0].codfolpag);
      if (self.comparacao[0].codfolpag) {
        self.copiar(self.comparacao[1], item);
        onde = 1;
        self.indice = {};
        self.carregandoIndice = true;
        self.$forceUpdate();
        api.listarIndice({
            codfolpag1: self.comparacao[0].codfolpag,
            numseqpcsfol1: self.comparacao[0].numseqpcsfol,
            codfolpag2: self.comparacao[1].codfolpag,
            numseqpcsfol2: self.comparacao[1].numseqpcsfol,
        }).then(function(lista) {
            self.indice = lista[0];
            self.carregandoIndice = false;
        });
        self.montarDiferencas();
      } else {
        self.copiar(self.comparacao[0], item);
      }
      if (item.tippcs == "L") {
        self.carregandoComparacao[onde] = true;
        api
          .obterProcLegado({
            codfolpag: self.folhaSelecionada,
            numseqpcsfol: item.numseqpcsfol
          })
          .then(function(resposta) {
            self.comparacao[onde].rubs = [];
            for (var i = 0; i < resposta.length; i++) {
              self.comparacao[onde].rubs[resposta[i].codrub] =
                resposta[i].vlrpag;
            }
            self.carregandoComparacao[onde] = false;
          });
      } else {
        self.carregandoComparacao[onde] = true;
        api
          .obterDadosProc({
            codfolpag: self.folhaSelecionada,
            numseqpcsfol: item.numseqpcsfol
          })
          .then(function(resposta) {
            self.comparacao[onde].rubs = [];
            for (var i = 0; i < resposta.length; i++) {
              self.comparacao[onde].rubs[resposta[i].codrub] =
                resposta[i].vlrpag;
            }
            self.carregandoComparacao[onde] = false;
          });
      }
    },
    copiar: function(p1, p2) {
      p1.codfolpag = p2.codfolpag;
      p1.numseqpcsfol = p2.numseqpcsfol;
      p1.dtacad = p2.dtacad;
      p1.tippcs = p2.tippcs;
      p1.rubs = [];
      if (p2.rubs) {
        for (var i in p2.rubs) {
          p1.rubs[i] = p2.rubs[i];
        }
      }
    },
    incluirExcecao: function() {
      var self = this;

      self.novaExcecao.nompesCodpes.map( (item) => {
        self.parametros.excecoes.push({
            codpes: item.codpes,
            nompes: item.nompes,
            dtafchfolecc:
              self.novaExcecao.dtafchfolecc.substring(8, 10) +
              "/" +
              self.novaExcecao.dtafchfolecc.substring(5, 7) +
              "/" +
              self.novaExcecao.dtafchfolecc.substring(0, 4) +
              " " +
              self.novaExcecao.dtafchfolecc.substring(11, 16)
          });
      });
      self.novaExcecao = {};
      self.multiplosCodpes = "";
      self.stringNompes = "";
    },
    excluirExcecao: function(item) {
      var self = this;
      for (var i in self.parametros.excecoes) {
        if (self.parametros.excecoes[i].codpes == item.codpes) {
          self.parametros.excecoes.splice(i, 1);
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted() {
    var self = this;
    for (var i = -1; i < 6; i++) {
      self.listaAno.push(new Date().getFullYear() - i);
    }
    api.obterUsuarioLogado().then(function(response) {
      self.usuarioLogado = response;
      self.parametros.dtafchfol = new Date().toJSON().slice(0, 10);
      self.parametros.dtafchfol = "";
      api.listarPermissoes({ permissao: "EXECUTAR" }).then(function(resposta) {
        self.podeExecutar = resposta.retorno == "S";
      });
      api.novaSessao().then(function(resposta) {
        self.parametros.id = resposta.id;
        clearInterval();
        setInterval(function() {
          if (self.estado == "executando") {
            api
              .acompanhar({
                id: self.parametros.id
              })
              .then(function(resposta) {
                self.console += resposta.logs;
                if (resposta.logs) {
                  setTimeout(function() {
                    document.getElementById("console").scrollTop = 1000000;
                  }, 100);
                }
                if (resposta.resumoPagamento.length > 0) {
                  self.resumo = resposta;
                  self.listarProc();
                  setTimeout(function() {
                    self.estado = "processado";
                  }, 2000);
                }
              });
            api
              .progresso({
                id: self.parametros.id
              })
              .then(function(resposta) {
                self.progresso = resposta.progresso;
                document.title = "Folha de Pagamento - Processamento";
                if (self.estado == "executando") {
                  document.title = self.progresso + "% - " + document.title;
                }
              });
          }
        }, 5 * 1000);
      });
      api.listarSimulacoes().then(function(resposta) {
        self.simulacoes = resposta;
      });
      api.listarUnidade().then(function(resposta) {
        self.unidades = resposta;
      });
      api.listarUltimasCargas().then(function(resposta) {
        self.ultimasCargas = resposta;
      });
      api.listarTiposProcessamento().then(function(resposta) {
        self.tiposProcessamento = resposta;
        api.listarTiposFolha().then(function(resposta) {
          self.tiposFolha = resposta;
          if (self.$route.query.codfolpag) {
            self.folha.ano = self.$route.query.ano;
            self.folha.mes = self.$route.query.mes;
            self.folha.codtipfol = self.$route.query.codtipfol;
            self.folha.tippcsfol = self.$route.query.tippcsfol;
          } else {
            self.anoAtual = new Date().getFullYear();
            self.mesAtual();
            self.folha.codtipfol = "1";
            self.folha.tippcsfol = "Normal";
          }
          //self.listarFolha();
        });
      });
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  }
};
</script>
<style lang="scss" scoped>
.portal-usp-conteudo {
  background-color: #d6d6ff;
}

iframe {
  background: white;
  overflow: scroll;
}

.body-filho {
  width: 100%;
  height: 100%;
  min-height: 800px;
  top: 0;
  left: 0;
  position: absolute;
}

.legado-loader {
  z-index: 8;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.2s linear infinite;
  margin-top: 8em;
}

.legado-loader h5{
  color: #ccc;
  text-align: center;
}

.card-header .float-left{
  display: flex;
    flex-direction: column;
}

.card-header h5{
  line-height: 1;
}

span.sistema{
  font-size: .9rem; font-weight: 400;
}

@keyframes spin {
  to { transform: rotate(360deg);}
}

@media screen and (max-width: 768px) {
        .card-header{
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          .perfis{
            padding: 1rem;
            display: flex;
            align-items: center;
            .dropdown-toggle {
          white-space: inherit;
      }
    }
  }
}

</style>