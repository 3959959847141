<template>
  <section style="min-height: 1000px;">
    <div class="container-fluid">
      <uspCartao titulo="Consulta de dados">
        <template slot="corpo">
          <ul class="nav nav-pills nav-fill">
			<li class="nav-item">
				<a :class="'nav-link' + (tipo == 'FOLHA' ? ' active' : '')" @click.prevent="alterarTipo('FOLHA')" href="#">Dados carregados</a>
			</li>
			<li class="nav-item">
		    	<a :class="'nav-link' + (tipo == 'CONGELADO' ? ' active' : '')" @click.prevent="alterarTipo('CONGELADO')" href="#">Dados originais</a>
			</li>
			<li class="nav-item">
		    	<a :class="'nav-link' + (tipo == 'ALTERACAO' ? ' active' : '')" @click.prevent="alterarTipo('ALTERACAO')" href="#">Altera&ccedil;&otilde;es de dados</a>
			</li>
		</ul>
        <form class="form-inline mt-3" v-show="tipo != ''">
            <input class="mr-sm-3 form-control" type="text" style="width: 180px;" v-model="parametros.codfolpag" placeholder="Folha de pagamento" v-show="tipo == 'FOLHA'" />
            <!--<select class="mr-sm-3 form-control" v-model="parametros.nomtab" v-show="tipo != 'FOLHA'">
                <option value=""></option>
                <option value="DadosFolha">Dados de folha</option>
                <option value="DadosServidores">Dados de servidores</option>
                <option :value="item.nomtab" v-for="item in tabelas" :key="item.nomtab" v-show="tipo == 'CONGELADO' || tipo == 'ALTERACAO'">{{ item.nomtab }}</option>
            </select>-->
            <input class="mr-sm-3 form-control" type="date" style="width: 180px;" v-model="parametros.dtaini" v-show="tipo != ''" />
            <input class="mr-sm-3 form-control" type="date" style="width: 180px;" v-model="parametros.dtafim" v-show="tipo == 'ALTERACAO'" />
            <input class="mr-sm-3 form-control" type="text" style="width: 180px;" v-model="parametros.codpes" placeholder="Número USP" v-show="tipo != ''" />
            <input class="mr-sm-3 form-control" type="text" style="width: 180px;" v-model="parametros.numseqsrv" placeholder="Sequencial" v-show="tipo == 'FOLHA'" />
            <button class="btn btn-primary" @click.prevent="buscar()" :disabled="processando">Buscar</button>
        </form>
		<div class="progress mt-3" v-if="progresso != 0 && progresso != 100">
            <div class="progress-bar" role="progressbar" :style="{width: progresso + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
<!--         <form class="form-inline"> -->
<!--             <label>Tabela:</label> -->
<!--             <select v-model="filtro.tabela" class="form-control"> -->
<!--                 <option value=""></option> -->
<!--                 <option :value="item.nomtab" v-for="item in lista">{{ item.nomtab }} ({{ item.alteracoes.length }})</option> -->
<!--             </select> -->
<!--             <label>Data:</label> -->
<!--             <select v-model="filtro.data" class="form-control"> -->
<!--                 <option value=""></option> -->
<!--             </select> -->
<!--         </form> -->
		<div class="table-responsive mt-3 mb-0" v-for="(tabela, index) in lista" v-if="Object.keys(lista).length > 0">
	        <table class="table mt-5 table-fixed">
	            <thead>
	                <tr>
	                    <th colspan="100%" class="text-primary">{{ index }}</th>
	                </tr>
	                <tr>
	                    <th v-if="!mesmasDatas">Inclus&atilde;o</th>
                        <th v-if="!mesmasDatas">Exclus&atilde;o</th>
	                    <th v-for="(valor, chave) in tabela[0]" v-if="chave != 'codpes' && chave != 'codpestem' && chave != 'nomtab' && chave != 'dtainivigddo' && chave != 'dtafimvigddo' && chave != 'dtainivigddo2' && chave != 'dtafimvigddo2' && chave != 'alteracao'">{{ converter(index, chave) }}</th>
	                </tr>
	            </thead>
	            <tbody>
	                <tr v-for="(item, ordem) in tabela" :class="(mesmasDatas || item.dtafimvigddo == '01/01/2078' ? '' : 'text-danger font-weight-bold') + ' ' + (!mesmasDatas && ordem != 0 && tabela[ordem - 1].alteracao != tabela[ordem].alteracao ? 'border-top-1' : '')">
	                    <td v-if="!mesmasDatas">{{ item.dtainivigddo2 }}</td>
                        <td v-if="!mesmasDatas">{{ item.dtafimvigddo2 != '2078-01-01 00:00:00.0' ? item.dtafimvigddo2 : "" }}</td>
	                    <td v-for="(valor, chave) in item" v-if="chave != 'codpes' && chave != 'codpestem' && chave != 'nomtab' && chave != 'dtafimvigddo' && chave != 'dtainivigddo' && chave != 'dtainivigddo2' && chave != 'dtafimvigddo2' && chave != 'alteracao'">{{ valor }}</td>
	                </tr>
	            </tbody>
	        </table>
        </div>
        </template>
      </uspCartao>
    </div>
  </section>
</template>
<script>
import NovaFolhaServico from "../dominio/novaFolha/NovaFolhaServico";
const api = NovaFolhaServico.build({});
export default {
  name: "ConsultaDados",
  data() {
    return {
        'lista': {},
        'progresso': 100,
        tabelas: [],
        parametros: {
            "dtaini": "",
            "dtafim": "",
            "nomtab": "DadosServidores"
        },
        tipo: "", /* FOLHA, CONGELADO, ALTERACAO */
        filtro: {
            "codpes": "",
            "data": "",
            "nomtab": ""
        },
        processando: false,
        mesmasDatas: false,
        tabelasColunas: {
            "MOVONSIG": {
                "codpes":"Número USP",    
                "numseqsrv":"Sequencial",    
                "codcgt":"Consignatária",       
                "codtipcgn":"Tipo",    
                "dtainivalcgn":"Validade", 
                "codfolpag":"Folha",    
                "numpcl":"Parcelas",       
                "vlrdes":"Valores",       
                "stadesefe":"Valor descontado",    
            },
            "ACTLIQUIDHORASERV":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",  
                "codacoctv":"Acordo",
                "codrub":"Rubrica",       
                "codfolpag":"Folha",    
                "numhormtolqd":"Descontos de horas / minutos",
            },
            "ACUMULACAO":{
                    "codpes": "Número USP",  
                    "numseqsrv":"Sequencial",    
                    "dtainiana":"Entrada de análise",    
                    "dtainiacu":"Início da acumulação",    
                    "dtafimacu":"Final da acumulação",    
                    "stapceacu":"Situação para parecer ilegal",   
                    "dtapceacu":"Data do parecer",    
                    "stapcercs":"Situção para parecer ilegal",    
                    "dtajulrcs":"Data do julgamento do recurso",    
                    "stades_ir":"Deve efetuar desconto de IR",    
                    "starcbbnf":"Recebimento de  benefício",    
                    "stadestto":"Descontar de teto",
            },
            "AFASTAMENTO":{
                    "codpes":"Número USP",       
                    "numseqsrv":"Sequencial",
                    "dtainioco":"Início",    
                    "tipoco":"Tipo de ocorrência",       
                    "codpubadm":"Publicação",
            },
            "AFASTREGTRABDOC":{
                "codpes":"Número USP",     
                "numseqsrv":"Sequencial",    
                "dtaingrgm":"Ingresso",    
                "dtainiafargm":"Início do afastamento", 
                "dtafimafargm":"Final do afastamento", 
                "tipjorafa":"Jornada do afastamento",    
                "tipjorpvd":"Tipo de jornada para previdência",    
            },
            "AGENDARH":{
                "codprxfolnrm":"Código da folha",
                "codfolpagcgt":"Pagamento de consignatárias",
            },
            "APOSENTADORIA":{
                "codpes":"Número USP",     
                "numseqsrv":"Sequencial",    
                "dtainiaps":"Início",    
                "codcla":"Classe",      
                "codfnc":"Código da função",       
                "tipcon":"Condição",       
                "tipjor":"Jornada",       
                "tipjorant":"Última jornada antes da aposentadoria",    
                "codund":"Unidade",       
                "codundfis":"Unidade fiscal",
                "codundhol":"Unidade de recebimento do hollerith",
                "tipaps":"Tipo de aposentadoria",
                "dtafimaps":"Fim da aposentadoria",
                "motfimaps":"Motivo do final da aposentadoria",
                "anotrb":"Anos trabalhados",
                "anoapsitg":"Anos para aposentadoria integral",
                "staholcrr":"Recebimento holerite correio",
                "nummesjoratl":"Meses na jornada atual",
                "pctaps":"Percentual de aposentadoria",
                "numdiartp":"Número de dias em jornada  RTP",
                "numdiartc":"Número de dias em jornada RTC",
                "numdiardi":"Número de dias em jornada RDIDP",
                "vlrisl":"Valor da insalubridade",
                "bsepagisl":"Percentual de insalubridade",
                "nummesislant":"Meses de recebimento de insalubridade",
                "stapcetce":"Parecer TCE",
                "codclaest":"Classe do estado",
                "nivgruaps":"Nivel / grau ",
                "tippvt":"Tipo de provimento",
                "gruisl":"Grau de insalubridade",
                "tipmer":"Mérito",
                "nivgruest":"Nível / grau no estado",
                "codtipven":"Tipo de vencimento",
                "codgrpcon":"Grupo / condição",
                "dtaaps":"Data da aposentadoria",
            },
            "APOSENTCOMPLCLT":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainiaps":"Início",
                "dtainiapsiii":"Início da aposent pelo INSS",
                "dtafimapsiii":"Final da aposent  pelo INSS",
                "vlrapsiii":"Valor",
                "dtactcvlr":"Crédito do valor",
            },
            "APOSENTMEDIASALARIO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainiaps":"Início",
                "dtainipvt":"Início do provimento",
                "dtafimpvt":"Final do provimento",
                "vlrpvt":"Valor",
                "vlrpagultbrt":"Ultimo valor bruto",
            },
            "BENEFICIOPA":{
                "codpesdpdpen":"Número USP dependente pensionista",
                "codpestem":"Número USP do servidor",
                "numseqsrv":"Sequencial",
                "codtipfol":"Tipo de folha ",
                "dtainicalpen":"Iníco do cálculo da pensão",
                "codtipbsepen":"Base para cálculo",
                "codpesdpd":"Número USP do beneficiário",
                "pctauxcch":"Percentual auxílio creche",
                "pctsalfam":"Percentual salário família",
                "dtafimauxcch":"Final de auxilio creche",
                "dtafimsalfam":"Final de salário família",
                "pctauxeduesp":"Auxílio educação especial",
                "dtafimauxesp":"Final do auxílio especial.",
            },
            "CALCBASEREPRES":{
                    "codbserpc":"Base de representação",
                    "codsgpcaa":"Subgrupo",
                    "nomcaa":"Carreira Usp",
                    "codtipven":"Tipo de vencimento",
                    "fxarefven":"Faixa / referência",
                    "nivgruven":"Nivel / grau",
                    "indmlp":"Índice multiplicador",
                    "dtainival":"Início",
            },
            "CALENDLOCALIDADEUSP":{
                "codloc":"Localidade",
                "dtacld":"Calendário",
                "codtipococld":"Tipos de ocorrências",
            },
            "CAMPUS":{
                "codloc":"Localidade",
                "codcam":"Campus",
                "numpticam":"Tipo do campus",
            },
            "CLASSE":{
                "codcla":"Classe",
                "nomabvcla":"Nome da classe",
                "dtadtvcla":"Desativação",
                "dtainicla":"Ínício",
                "nivrefini":"Referência",
                "nivgruini":"Nível / grau ",
                "nomcaa":"Carreira",
                "codsgpcaa":"Subgrupo",
                "paggralid":"Gratificação",
                "pagcoehon":"Coeficientes honorários",
                "tipcla":"Tipo da classe",
            }, 
            "COLEGCOMPOSICAO":{
                "codclg":"Colegiado ",
                "sglclg":"Sigla",
                "numseqcpo":"Sequencial",
                "codgraclg":"Gratificação",
                "codvbarpc":"Representação",
            },
            "COMISSIONAMENTO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainicms":"Início",
                "staitrislprl":"Interrompe insal. / peric.",
                "dtafimcms":"Final",
                "tipjor":"Jornada",
                "codfncexr":"Funçãos externa",
                "anoapsesp":"Aposentadoria especial",
                "dtainibnf13s":"Início do benefício / 13º",
            },
            "COMPLHORAEXTRA":{
                    "codpes":"Número USP",
                    "numseqsrv":"Sequencial",
                    "dtainihorexx":"Data início",
                    "horinihorexx":"Hora inicial",
                    "tiphorexx":"Tipo de hora",
                    "numhorexx":"Número de horas",
            },
            "COMPLPENSAOALIMENT":{
                "codpesdpdpen":"Número USP dependente",
                "codpestem":"Número USP do servidor ",
                "numseqsrv":"Sequencial",
                "codtipfol":"Folha",
                "dtainicalpen":"Início do cáculo da pensão ",
                "codtipbsepen":"Base de cálculo",
                "dtafimcalpen":"Final",
                "stareames_01":"Reajuste em janeiro",
                "stareames_02":"Reajuste em fevereiro",
                "stareames_03":"Reajuste em março",
                "stareames_04":"Reajuste em abril",
                "stareames_05":"Reajuste em maio",
                "stareames_06":"Reajuste em junho",
                "stareames_07":"Reajuste em julho",
                "stareames_08":"Reajuste em agosto",
                "stareames_09":"Reajuste em setembro",
                "stareames_10":"Reajuste em outubro",
                "stareames_11":"Reajuste em novembro",
                "stareames_12":"Reajuste em dezembro",
                "vlrfix":"Valor",
                "taxbse":"Taxa base",
                "staadi13s":"Pago no adiant do 13º sal",
            },
            "COMPLPERAQFERIAS":{
                    "codpes":"Número USP",
                    "numseqsrv":"Sequencial",
                    "dtainiperaqs":"Início do perído aquisitivo",
                    "tiphorexx":"Tipo de hora extra ",
                    "medhorexx":"Média de hora extra",
            },
            "COMPLPESSOASERV":{
                "codpes":"Número USP",
                "numdoctrb":"Carteira de trabalho",
                "serdoctrb":"Série",
                "sglesttrb":"Estado",
                "codpastrb":"País",
            },
            "COMPLTABVALORINSS":{
                "dtainiiii":"Início",
                "fxainisalctb":"Faixa inicial",
                "fxafimsalctb":"Faixa final",
                "taxvaliii":"Percentual de desconto",
            },
            "COMPLTABVALORIR":{
                "dtaini_ir":"Início",
                "fxasalini":"Faixa inicial",
                "fxasalfim":"Faixa final",
                "taxval_ir":"Valor tributável",
                "vlrdes":"Valor a ser abatido",
            },
            "CONSIGNACAO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codcgt":"Consignatária",
                "codtipcgn":"Tipo",
                "dtainivalcgn":"Início",
                "dtafimvalcgn":"Final",
                "numpcl":"Parcelas",
                "vlrtot":"Valor",
            },
            "CONTABANCPESSOA":{
                "codpes":"Número USP",
                "numbco":"Banco",
                "numage":"Agência",
                "numctabco":"Conta",
                "digctlcta":"Dígito",
            },
            "DECTERCCONSIG":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codcgt":"Consignatária",
                "codtipcgn":"Tipo",
                "dtainivalcgn":"Validade ",
                "dtaini13s":"Início",
                "dtafim13s":"Final",
                "stades13s":"Desconto no décimo terceiro",
            },
            "DEPENDENCIA":{
                "codpestem":"Número USP do servidor",
                "codpesdpd":"Número USP do dependente",
                "tipdpd":"Tipo de dependência",
                "dtainidpd":"Início",
                "dtafimdpd":"Final",
                "tipctgusu":"Categoria de usuário",
            },
            "DEPENDENTE":{
                "codpestem":"Número USP do servidor",
                "codpesdpd":"Número USP do dependente",
                "dtainival":"Validade",
                "dtafimval":"Final",
                "gruprt":"Grau de parentesco",
            },
            "DESIGAFASTELEICAO":{
                "codfncetr":"Função",
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainidsg":"Início",
                "dtainiafaelc":"Afastamento em",
                "dtafimafaelc":"Final",
            },
            "DESIGNACAO":{
                "codfncetr":"Função",
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainidsg":"Início",
                "dtafimdsg":"Fim",
                "tipdsg":"Tipo de designação",
                "stacms":"Designação acumulada",
                "codund":"Unidade",
            },
            "DESLIGAMENTO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "numseqdlg":"Sequencial do desligamento",
                "tipvac":"Vacância",
                "dtavac":"Data",
                "stadlg":"Desligamento",
            },
            "EMPRESTIMOSERVIDOR":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainipvm":"Início",
                "dtainiepo":"Empréstimo em",
                "dtafimepo":"Final em",
                "pctadctrf":"Percentual",
                "codset":"Setor",
            },
            "ENDPESSOA":{
                "codpes":"Número USP",
                "numseqendpes":"Sequencial",
                "codloc":"Localidade",
                "tipend":"Tipo de endereço",
                "epflgr":"Endereço",
                "codendptl":"Cep",
                "nombro":"Bairro",
                "numlgr":"Número",
                "cpllgr":"Cep",
            },
            "ENDUSP":{
                "codundfis":"Unidade fiscal",
                "numseqendusp":"Sequencial",
                "codund":"Unidade'",
                "codloc":"Localidade",
                "starefundfis":"Referência",
            },
            "ESTAGIARIO":{
                "codpes":"Número USP",
                "numseqeto":"Sequencial",
                "dtaingeto":"Ingresso",
                "dtadlgeto":"Desligamento",
            },
            "ESTAGIO":{
                "codpes":"Número USP",
                "numseqeto":"Sequencial",
                "dtainivaleto":"Validade",
                "dtafimvaleto":"Final",
                "dtainieto":"Início",
                "codpostrb":"Posto de trabalho",
                "tippvm":"Tipo de provimento",
                "tipvac":"Vacância",
                "tipcon":"Condição",
                "tipjor":"Jornada",
                "niveto":"Nível",
                "codgrpcon":"Grupo / condição",
                "dessegacipes":"Seguro acidente",
            },
            "ESTAGIOAUXTRANSP":{	
                "codpes":"Número USP",
                "dtainieto":"Iníco",
                "dtainiper":"Período",
                "numdiafrq":"Dias",
                "dtactcfol":"Folha",
                "numseqeto":"Sequencial",
            },
            "ESTAGIORH":{
                "codpes":"Número USP",
                "dtainieto":"Início",
                "dtafimeto":"Final",
                "numseqeto":"Sequencial",
                "dtaingeto":"Ingresso",
                "stapagcvn":"Convênio ",
                "tipenceto":"Encerramento",
                "codcla":"Classe",
                "codfnc":"Função",
                "tipcon":"Condição",
                "codgrpcon":"Grupo / condição",
                "tipjor":"Jornada",
                "codund":"Unidade",
                "codundfis":"Unidade fiscal",
                "codset":"Setor",
                "codorircs":"Origem de recursos",
                "dessegacipes":"Seguro",
            },
            "FAVULSAPERIODO":{
                "codfolpag":"Folha",
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainiperavu":"Início do período",
                "dtafimperavu":"Final do período",
                "codcladsg":"Classe designada",
                "codorircs":"Origem de recursos",
                "codpostrb":"Posto de trabalho",
                "codund":"Unidade",
                "codundfis":"Unidade fiscal",
                "codclaest":"Classe estado ",
                "codset":"Setor",
                "codclausp":"Classe USP",
                "codfnc":"Função",
                "tipmer":"Mérito",
                "codgrpcon":"Grupo / condição",
                "codtipven":"Tipo de vencimento	",
                "tipjor":"Jornada",
                "nivgrufolavu":"Nível / grau",
                "fxareffolavu":"Faixa / referência",
                "dtaopcfgtavu":"Opção FGTS",
            },
            "FERIAS":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipoco":"Ocorrência",
                "dtainioco":"Início",
                "dtainiperaqs":"Período aquisitivo",
                "numper":"Número",
                "staopc13s":"Opção 13º sal",
                "stavdafer":"Opção de abono",
                "codfolpag":"Folha",
            },	
            "FOLHAPGTO":{
                "codfolpag":"Folha",
                "dtactcfol":"Crédito",
                "tippcs":"Tipo de processamento",
                "codtipfol":"Folha",
                "tippcsfol":"Tipo de processamento",
                "idfqizfer":"Quinzena de férias",
            },
            "FUNCAO":{
                "codfnc":"Função", 
                "dtainifnc":"Início",
                "dtadtvfnc":"Desativação da função",
                "nomabvfnc":"Nome abreviado",
                "tipmer":"Mérito",
                "codclsbraocp":"Classificação de ocupações ",
                "tipjormax":"Jornada máxima",
            },
            "GRUPOCONDICAO":{
                "codgrpcon":"Grupo / condição",
                "tipcon":"Condição",
            },
            "HISTPOSTOTRABALHO":{
                "codpostrb":"Posto de trabalho",
                "dtainihstpos":"Início do histórico",
                "dtafimhstpos":"Final do histórico",
                "gruisl":"Grau de insalubridade",
                "pctprl":"Percentual de periculosidade",
                "tippos":"Tipos do posto",
                "stamapdin":"Manipula dinheiro",
                "codund":"Unidade",
                "codundfis":"Unidade fiscal",
                "codset":"Setor",
                "codlocusp":"Localidade USP",
                "codcla":"Classe",
                "codfnc":"Função",
                "codclaest":"Classe no estado",
                "codpadhor":"Padrão de horário",
                "codorircs":"Origem de recursos",
                "codcladsgest":"Classe no estado",
                "anoapsesp":"Anos para posentadoria especial",
                "pagetp":"Paga etapa",
                "stabsecalprl":"Base de cálculo para periculosidade",
            },
            "HISTVENCIMENTO":{
                "nomcaa":"Carreira",
                "codsgpcaa":"Subgrupo",
                "codtipven":"Tipo de vencimento",
                "fxarefven":"Faixa / referência",
                "nivgruven":"Nivel / grau",
                "dtainihstven":"Início do histórico",
                "dtafimhstven":"Final do histórico",
                "vlrven":"Vencimento",
            },
            "INGRESSOESPECIAL":{
                "codingesp":"Ingresso especial ",
                "tipingesp":"Tipo de ingresso",
                "codund":"Unidade",
                "codpostrb":"Posto de trabalho",
            },
            "JORNADA":{
                "tipjor":"Jornada",
                "numhorsmntrb":"Horas semanais trabalhadas",
                "codtipven":"Tipo de vencimento",
            },
            "LOCALIDADE":{
                "codloc":"Localidade",
                "sglest":"Sigla estado",
                "codpas":"País",
                "cidloc":"Cidade",
            },
            "LOCALIDADEUSP":{
                "codloc":"Localidade",
                "rgiauxtrp":"Região para auxílio-transporte",
            },
            "LOCALUSP":{
                "codlocusp":"Localidade USP ",
                "codund":"Unidade",
                "codundfis":"Unidade fiscal",
                "numseqendusp":"Sequencial",
            },
            "MOVCONSIG":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codcgt":"Consignatária",
                "codtipcgn":"Tipo",
                "dtainivalcgn":"Validade",
                "codfolpag":"Folha",
                "numpcl":"Parcelas",
                "vlrdes":"Valores",
                "stadesefe":"Situação",
            },
            "MOVCOTAMENSAL":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codfolpag":"Folha",
                "codrub":"Rubrica",
                "codgrpcon":"Grupo / condição",
                "numseqmov":"Sequêncial do movimento",
                "numcot":"Cotas",
                "vlrcot":"Valor",
                "codpesdpd":"Dependente",
            },
            "MOVDESCFOLHA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codrub":"Rubrica",
                "dtainidesfol":"Início do desconto",
                "codgrpcon":"Grupo / condição",
                "dtafimdesfol":"Final do desconto",
                "vlrdesfol":"Valor total",
                "txabsedesjud":"Base para desconto judicial",
                "tipbsedesjud":"Tipo de desconto judicial",
                "numtotpcl":"Total de parcelas",
                "codvbarpc":"Código da verba de representação",
                "codpubadm":"Publicação",
                "codreadesfol":"Tipo de reajuste do desconto",
                "codpesinr":"Interessado",
                "numbcoinr":"Banco do interessado",
                "numageinr":"Agência do interessado",
                "numctabcoinr":"Conta bancária para dep. do desc. judicial",
                "digctlctainr":"Dígito de controle",
                "numref":"Número de referência",
                "vlrdvdref":"Valor de referência",
                "codunddstref":"Unidade de referência.",
                "dtainidvdref":"Início da dívida de referência",
                "numhormtomov":"Horas / minutos a descontar / pagar",
            },
            "MOVFOLHA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codfolpagori":"Folha geradora",
                "codfolpagdst":"Folha de destino",
                "codrub":"Rubrica",
                "codgrpcon":"Grupo / condição",
                "vlrmov":"Valor",
                "tipacamovfol":"Tipo de ação",
                "codpesdpd":"Número USP dependente PA",
                "numtotpcl":"Total de parcelas",
                "stadesefe":"Sitação do desconto",
                "codfolpagrap":"Folha de reaplicação",
            },
            "MOVFORCADO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codfolpag":"Folha",
                "codrub":"Rubrica",
                "codgrpcon":"Grupo / condição",
                "codpesdpd":"Número USP dependente PA",
                "tipaca":"Ação",
                "vlrmov":"Valor",
                "starclvandes":"Recalcular vantagem / desconto",
                "stadesefe":"Sitação do desconto",
                "modacacal":"Modelo de ação no cálculo",
                "codfolpagrap":"Folha de reaplicação",
            },
            "OCORHORAEXTRA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainihorexx":"Data início de horas extra",
                "horinihorexx":"Hora extra início",
            },
            "OCORRENCIA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipoco":"Ocorrência",
                "dtainioco":"Início",
                "dtafimoco":"Final",
                "deshormto":"Desconto horas / minutos",
            },
            "OCORRENCIAESTAG":{
                "codpes":"Número USP",
                "numseqeto":"Sequencial",
                "tipoco":"Ocorrência",
                "dtainioco":"Início",
                "dtafimoco":"Final",
            },
            "OCORRENCIAESTAGRH":{
                "codpes":"Número USP",
                "tipoco":"Ocorrência",
                "dtainioco":"Início",
                "dtafimoco":"Final",
            },
            "PADRAOHORARIO":{
                "codpadhor":"Padrão",
                "numdiasmn":"Número de dias",
                "stapagadcntu":"Adicional noturno",
            },
            "PAIS":{
                "codpas":"Código",
                "nompas":"País",
            },
            "PARCMOVDESCFOLHA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codrub":"Rubrica",
                "dtainidesfol":"Início do desconto ",
                "numpcl":"Parcelas",
                "codgrpcon":"Grupo / condição",
                "vlrdes":"Valor",
                "stadesefe":"Situação do desconto",
                "tipaca":"Ação",
                "codfolpagpcl":"Folha para desconto",
                "codfolpagrap":"Folha de reaplicação",
            },
            "PARCMOVFOLHA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codfolpagori":"Folha de origem",
                "codfolpagdst":"Folha de destino",
                "codrub":"Rubrica",
                "numpcl":"Parcelas",
                "codgrpcon":"Grupo / condição",
                "vlrdes":"Valores",
                "stadesefe":"Sitação do desconto",
                "tipaca":"Ação",
                "codfolpagpcl":"Folha para desconto",
                "codfolpagrap":"Folha de reaplicação",
            },
            "PARTICIPANTECOLEG":{
                "codclg":"Número do colegiado",
                "sglclg":"Colegiado",
                "tipfncclg":"Função ",
                "codpes":"Número USP",
                "dtainimdt":"Início",
                "dtafimmdt":"Final",
                "numseqcpo":"Sequencial",
            },
            "PERAQFERIAS":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainiperaqs":"Início período aquisitivo",
                "medcoehonmdc":"Média dos honorários",
                "totdia":"Número de dias",
                "star_x":" Operado de raio_x",
                "medadcntu":"Média de adicionais noturnos",
                "medadcprl":"Média de adicional de periculosidade",
                "medadcisl":"Média de adicional de insalubridade",
            },
            "PESSOA":{
                "codpes":"Número USP",
                "nompes":"Nome",
                "dtanas":"Nascimento",
                "tipdocidf":"Documento de identificação",
                "numdocidf":"Número",
                "dtafimvalidf":"Validade",
                "sexpes":"Sexo",
                "numdocfmt":"Número de doc. formatado",
                "numcpf":"CPF",
                "sglest":"Estado",
            },
            "POSTOTRABALHO":{
                "codpostrb":"Posto de trabalho",
                "dtainivalpos":"Validade",
                "gruisl":"Grau de insalubridade",
                "pctprl":"Percentual de periculosidade ",
                "tippos":"Tipo de posto",
                "stamapdin":"Manipula dinheiro",
                "codund":"Unidade",
                "codundfis":"Unidade fiscal",
                "codset":"Setor",
                "codlocusp":"Localidade USP",
                "codcla":"Classe",
                "codfnc":"Função",
                "codclaest":"Classe no estado",
                "codpadhor":"Padrão",
                "codorircs":"Origem de recursos",
                "codcladsgest":"Classe no estado",
                "anoapsesp":"Aposentadoria especial",
                "pagetp":"Paga etapa",
                "stabsecalprl":"Base de cálculo para adic. de peric.",
            },
            "PROVIMENTO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainipvm":"Início",
                "dtaexelmn":"Validade",
                "tippvm":"Tipo de provimento",
                "tipvac":"Vacância",
                "tipcon":"Condição",
                "tipjor":"Jornada",
                "tipmer":"Mérito",
                "dtafimpvm":"Final",
                "dtainictr":"Início de contrato",
                "dtaopcfgt":"Opção FGTS",
                "nivgrupvm":"Nivel / grupo",
                "codtipven":"Tipo de vencimento",
                "nivgruest":"Nível / grau",
                "fxarefpvm":"Faixa / referência",
                "codgrpcon":"Grupo / condição",
                "codpostrb":"Posto de trabalho",
            },
            "PUBLICADMIN":{
                "codpubadm":"Publicação",
                "dtaemidoc":"Emissão do documento",
            },
            "RUBRICA":{
                "codrub":"Rubrica",
                "stasalctbclt":"Salário contribuição CLT",
                "poshol":"Posição do hollerith",
                "nomrub":"Nome da rubrica",
                "starub":"Situação",
                "tiprub":"Tipo de rubrica",
                "starai":"RAIS",
                "stasalctbatq":"Sálario contribuição autárquico",
                "stacgt":"Consignatária",
                "pctcmscgt":"Percentual de comissão da consignatária",
                "tiptbt_ir":"Tipo de tributo do IR",
                "codrubrep":"Rubrica de reposição",
                "staposcal":"Posição do cálculo",
                "tipemp":"Tipo de empenho",
                "stapvdcplclt":"Previdência complementar - CLT",
                "stapvdcplatq":"Previdência complementar - autárquicos",
                "stahorexxclt":"Cálculo de horas extras - CLT",
                "stahorexxatq":"Cálculo de horas extras - autárquicos",
            },
            "RUBRICACONVCODIGO":{
                "codrub":"Rubrica",
                "codrubpds":"Rubrica PRODESP",
                "tiprubpds":"Tipo rubrica PRODESP",
            },
            "SALARIOCLASSE":{
                "codcla":"Classe",
                "dtainisal":"Início",
                "vlrsal":"Valor",
                "dtafimsal":"Final",
            },
            "SERVIDOR":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtaing":"Ingresso",
                "dtadlg":"Desligamento",
                "dtainiipe":"Início de contribuição - IPESP",
                "stacadsrv":"Situação de cadastro ",
                "codingesp":"Ingresso especial USP",
            },
            "SETOR":{
                "codset":"Setor",
                "nomabvset":"Nome",
                "dtadtvset":"Ativação",
                "dtainival":"Validade",
            },
            "SITUACAOESPIR":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainisitesp":"Início da situação",
                "dtafimsitesp":"Final",
                "tipsitesp":"Tipo de situação",
            },
            "SPPREVCOMOPCAO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtaopcsppcpl":"Data da opção",
                "pctopcsppcpl":"Percentual",
                "staval":"Validação da opção - SPPREV",
                "staacmttoiii":"Acima do teto - INSS",
                "codfolpagprx":"Próxima folha",
                "staatzdestto":"Autoriza desconto teto",
                "stasemcrrptr":"Sem contrapartida do patrocinador",
            },
            "SUSPENSAOPGTO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainisuspag":"Início",
                "dtafimsuspag":"Final",
                "codtipsuspag":"Motivo",
                "stapagper":"Reprocessar folhas",
            },
            "TABVALORINSS":{
                "dtainiiii":"Início",
                "dtafimiii":"Final",
                "limsalctbiii":"Limite de salário",
                "pctlimsaliii":"Percentual limite",
            },
            "TABVALORIR":{
                "dtaini_ir":"Início", 
                "dtafim_ir":"Final",
                "vlrdeddpd":"Valor de dedução - dependente",
                "vlrdedina":"Valor de dedução aposent + 65 anos ",
                "psosal_ir":"Piso salarial",
                "vlr_irmin":"Valor mínimo ",
                "vlrlimdespad":"Valor limite desconto padrão",
            },
            "TICKETS":{
                "codfolpagvda":"Folha de pagamento",
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "codrub":"Rubrica",
                "numtik":"Numero de tickets",
                "stadesefe":"Situação do desconto",
                "tipaca":"Ação",
            },
            "TIPOBASECONSIG":{
                "codcgt":"Consignatária",
                "codtipcgn":"Tipo",
                "tippagcgn":"Tipo de desconto",
                "dtaexo":"Extinção",
                "codrub":"Rubrica",
            },
            "TIPOFUNCAOESTRUT":{
                "nomabvfncetr":"Nome",
                "codvbarpc":"Verba de representação",
                "dtainival":"Início",
            },
            "TIPOFUNCESTRESTADO":{
                "nomabvfncest":"Nome",
                "codvbarpc":"Verba de representação",
            },
                "TIPOHORAEXTRA":{
                "tiphorexx":"Tipo de hora",
                "pcthorexx":"Percentual",
            },
                "TIPOOCORCALEND":{
                "codtipococld":"Tipos de ocorrências",
                "stadiautl":"Dia útil",
                "stadiatrb":"Dia de trabalho",
            },
            "TIPOOCORRENCIA":{
                "tipoco":"Tipo",
                "dtainivaltip":"Validade",
                "stadiatrb":"Dia de trabalho",
                "staefeexe":"Efetivo exercício",
                "stapag_ac":"Pagamento de auxílio creche",
                "sta13sppc":"Proporcionaliza 13º",
                "numtrcflt":"Terço faltas",
            },
            "TIPOTICKET":{
                "codrub":"Rubrica",
                "dtaatv":"Ativação",
                "dtadtv":"Desativação",
            },
            "TITULOPES":{
                "codpes":"Número USP",
                "numseqtitpes":"Sequencial do título",
                "nivesc":"Escolaridade",
            },
            "UNIDADE":{
                "codund":"Código",
                "codcam":"Campus",
                "nomabvund":"Nome",
                "dtainival":"Validação",
                "numpticam":"Partição campus",
            },
            "UNIDADEFISCAL":{
                "codundfis":"Unidade fiscal",
                "codund":"Unidade",
                "idfcgc":"CNPJ da unidade",
                "dtadtv":"Ativação",
                "dtainival":"Início",
                "taxsegaci":"Seguro acidente",
                "codatvibg":"Atividade principal",
            },
            "VALORFIXO":{
                "codtipvlr":"Tipo valor",
                "dtainivlrfix":"Vigência",
                "dtafimvlrfix":"Final da vigência",
                "vlrfix":"Valor fixo",
            },
            "VALORPROGRESSIVO":{
                "codtipvlr":"Tipo valor",
                "dtainivlrpgs":"Vigência",
                "fxainivlrpgs":"Valor de início da faixa ",
                "fxafimvlrpgs":"Valor final da faixa ",
                "txavlrpgs":"Percentual da faixa",
                "vlrpgs":"Valor da faixa ",
                "dtafimvlrpgs":"Final da vigência ",
            },
            "VALORREGIONAL":{
                "codtipvlr":"Tipo valor",
                "rgiauxtrp":"Região administrativa",
                "dtainivlrrgi":"Início",
                "dtafimvlrrgi":"Final",
                "vlrrgi":"Valor regional",
            },
            "VALORTABCNPJ":{
                "codtipvlr":"Tipo de valor",
                "idfcgc":"CNPJ da unidade",
                "dtainivlrcpj":"Início",
                "dtafimvlrcpj":"Final",
                "taxsegaci":"Seguro acidente",
            },
            "VALORTETO_EC41_2003":{
                "codpes":"Número USP ",
                "numseqsrv":"Sequencial",
                "codgrpcon":"Grupo / condição",
                "codrub":"Rubrica",
                "vlrpag":"Valor",
                "dtafimval":"Final",
            },
            "VANTCOMPLART133":{
                "codpes":"Número USP ",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainicplsal":"Início",
                "codcla":"Clase",
                "dtainiinpdmo":"Início da incorporação",
                "dtafiminpdmo":"Final da incorporação",
                "numdmoinp":"Décimos de incorporação",
            },
            "VANTCOMPLSALARIO":{
                "codpes":"Número USP ",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainicplsal":"Início",
                "tipmer":"Mérito",
                "dtafimcplsal":"Final",
                "codcla":"Classe",
                "codfnc":"Função",
                "epfjorcpl":"Especificação de jornada",
                "fxarefcpl":"Faixa / referência",
                "nivgrucpl":"Nível / grupo",
                "pagmerdct":"Paga mérito ",
                "codtipven":"Tipo de vencimento",
                "gruisl":"Grau de insalubridade",
                "pctprl":"Percentual de periculosidade",
            },
            "VANTCOMPLSALVENC":{
                "codpes":"Número USP ",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainicplsal":"Início",
                "dtainicplven":"Início da complementação",
                "dtafimcplven":"Final da complementação",
                "nomcaa":"Carreira",
                "codsgpcaa":"Subgrupo",
                "codtipven":"Tipo de vencimento",
                "fxarefven":"Faixa / referência",
                "nivgruven":"Nivel / grau",
                "vlrcplsal":"Complementação ",
                "tipmer":"Mérito",
            },
            "VANTESPDOCENTE":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainivanesp":"Início",
                "dtafimvanesp":"Final",
                "numanocsd":"Anos considerados",
            },
            "VANTGRATIF":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainivangra":"Início",
                "dtafimvangra":"Final",
                "vlrvan":"Valor",
            },
            "VANTGRATIFLORENA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainivan":"Início",
                "dtafimvan":"Final",
                "codtipvlr":"Tipo de valor",
            },
            "VANTHORARIO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainivanhor":"Início",
                "dtafimvanhor":"Final",
                "pctinp":"Percentual ",
            },
            "VANTINFORMATIVA":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainidii":"Início",
                "dtafimdii":"Final",
                "numdia":"Número de dias",
            },
            "VANTINSAL":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainiisl":"Início",
                "dtafimisl":"Final",
                "pctbaseisl":"Porcentagem base",
                "bseisl":"Base do adicional",
            },
            "VANTISENCAO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainiisc":"Início",
                "dtafimisc":"Final",
            },
            "VANTJUDICIAL":{
                "codpes":"Número USP",
                "tipvan":"Vantagem",
                "dtainivanjud":"Início",
                "dtafimvanjud":"Final",
                "tipcurnot":"Curso noturno",
                "codund":"Unidade",
                "pctisl":"Percentual de insalubridade",
                "bseisl":"Base do adicional",
                "codcla":"Classe",
                "tipjor":"Jornada",
                "pctprl":"Percentual de periculosidade",
                "vlrvan":"Valor",
                "codvbarpc":"Verba de representação",
            },
            "VANTPESSERVIDOR":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainivansrv":"Início",
                "dtainivanreu":"Validade da vantagem",
                "dtafimvansrv":"Final da vantagem",
            },
            "VANTTEMPOSERVICO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainitmp":"Início",
                "dtafimtmp":"Final",
                "numqui":"Quinquênio",
            },
            "VANTREUNIDA":{
                "codpes":"Número USP",
                "tipvan":"Vantagem",
                "dtainivanreu":"Início",
                "dtafimvanreu":"Final",
                "bseisl":"Base do adicional",
                "pctisl":"Percentual de insalubridade",
            },
            "VENCCUMULATIVO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "dtainiven":"Início",
                "tipvencum":"Vencimento cumulativo",
                "dtafimven":"Final",
                "salctbiii":"Salário contribuição - INSS",
                "vlrdesfoaiii":"Valor de INSS.",
                "ventbt_ir":"Vencimento tributável - IR",
                "stadestot":"",
            },
            "VENCIMENTO":{
                "nomcaa":"Carreira",
                "codsgpcaa":"Subgrupo",
                "codtipven":"Tipo de vencimento",
                "fxarefven":"Faixa / referência",
                "nivgruven":"Nivel / grau",
                "dtainival":"Início",
                "vlrven":"Vencimento",
            },
            "VERBAREPRES":{
                "codvbarpc":"Verba de representação",
                "pctvbarei":"Percentual da verba do Reitor",
                "indest":"Índice do Estado",
                "vlrfix":"Valor",
                "dtainivba":"Início",
                "dtadtvvba":"Ativação",
                "codbserpc":"Base de representação",
            },
            "VERBAREPRESINC":{
                "codpes":"Número USP",
                "numseqsrv":"Sequencial",
                "tipvan":"Vantagem",
                "dtainivbainp":"Início",
                "dtafimvbainp":"Final",
                "codtipfncetr":"Tipo de função ",
                "codvbarpc":"Código da verba",
                "nomabvfncest":"Nome abreviado da função",
                "nummesinp":"Meses de incorporação",
                "stavbainpest":"Externa (estado)",
                "pctvbainp":"Percentual da verba do reitor",
                "dtasolpag":"Solicitação ",
                "dtafimpag":"Final da solicitação",
                "numgrpperapu":"Grupos apurados para incorporação",
            },
            "VERBARINCDECIMO":{
                "codpes":"Número USP",
                "numseqsrv":"Sequêncial",
                "tipvan":"Vantagem",
                "dtainivbainp":"Início",
                "dtainiinpdmo":"Direito à incorporação",
                "numseqinpdmo":"Sequencial de incorporação ",
                "stavbaest":"Verba do estado",
                "codtipfncetr":"Tipo de função ",
                "numdmoinp":"Décimos de incorporação ",
                "nomabvfncest":"Nome abreviado da função",
                "pctvbainp":"Percentual incorporado",
                "dtasolpag":"Solicitação",
                "dtafimpag":"Final da solicitação",
            }
        }
    };
  },
  methods: {
    alterarTipo: function(tipo) {
        var self = this;
        self.tipo = tipo;
        self.lista = {};
        //self.parametros.nomtab = "";
    },
    converter: function(tabela, coluna) {
        var self = this;
        return tabela in self.tabelasColunas ? self.tabelasColunas[tabela][coluna] : coluna;
    },
    buscar: function() {
        var self = this;
        var formatarData = function(data) {
			if (data) {
				return data.substring(8, 10) + "/" + data.substring(5, 7) + "/" + data.substring(0, 4);
			}
			return "";
		};
        self.lista = {};
        self.mesmasDatas = (self.tipo == "CONGELADO" || self.tipo == "FOLHA");
        if (self.tipo == "FOLHA") {
            self.processando = true;
            api.consultarDados({dtaini: formatarData(self.parametros.dtaini), codfolpag: self.parametros.codfolpag, codpes: self.parametros.codpes, numseqsrv: self.parametros.numseqsrv, modo: "S"})
            .then(function(response) {
                self.lista = response;
                self.processando = false;
            });
        } else {
            var tabelas = [];
            for (var i in self.tabelas) {
                if (self.parametros.nomtab == "" || (self.parametros.nomtab == "DadosFolha" && self.tabelas[i].codpescol == "") || (self.parametros.nomtab == "DadosServidores" && self.tabelas[i].codpescol != "") || self.parametros.nomtab == self.tabelas[i].nomtab) {
                    tabelas.push(self.tabelas[i]);
                }
            }
            self.progresso = 0;
            var contador = 0;
            self.processando = true;
            for (const i in tabelas) {
                api.listarDadosAlteracoes({dtaini: formatarData(self.parametros.dtaini), dtafim: self.tipo == "ALTERACAO" ? formatarData(self.parametros.dtafim) : formatarData(self.parametros.dtaini), nomtab: tabelas[i].nomtab, nomcol: tabelas[i].nomcol, codpescol: tabelas[i].codpescol, codpes: self.parametros.codpes, nomcolcha: tabelas[i].nomcolcha}).then(function(response) {
                    if (response.length > 0) {
                        self.lista[tabelas[i].nomtab] = response;
                    }
                }).finally(function() {
                    contador++;
                    if (contador >= tabelas.length) {
                        self.processando = false;
                    }
                    self.progresso = contador * 100.0 / tabelas.length;
                });
            }
        }
    }
  },
  mounted() {
    var self = this;
    self.parametros.dtaini = self.parametros.dtafim = new Date().getFullYear() + "-" + ("00" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("00" + new Date().getDate()).slice(-2);
    api.listarTabelas().then(function(response) {
        self.tabelas = response;
        if (self.$route.query.tipo) {
            self.alterarTipo(self.$route.query.tipo);
            self.parametros.codfolpag = self.$route.query.codfolpag;
            self.parametros.dtaini = self.$route.query.dtaini;
            self.parametros.dtafim = self.$route.query.dtafim;
            self.parametros.codpes = self.$route.query.codpes;
            self.parametros.numseqsrv = self.$route.query.numseqsrv;
            self.buscar();
        }
    });
  }
};
</script>
<style>
</style>
