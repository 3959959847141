<template>
  <section style="min-height: 2000px;">
    <div class="container-fluid" style="min-height: 5000px;">
      <uspCartao :titulo="codfolpag + ' / ' + numseqpcsfol">
        <template slot="corpo">
          <pre style="overflow-y: scroll; white-space: pre-wrap;" v-html="procEdicao.detalhes"></pre>
        </template>
      </uspCartao>
      <uspCartao titulo="Pagamentos e movimentos" fechado="true">
        <template slot="corpo">
          <div class="row">
            <div class="col-sm table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col" style="width: 20%;">Rubrica</th>
                  <th scope="col" style="width: 40%;">Pagamento</th>
                  <th scope="col" style="width: 20%;">Quantidade</th>
                  <th scope="col" style="width: 20%;" class="text-right">Valor (R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in resumoPagamento" :key="item.codrub">
                  <td>{{ item.codrub }}</td>
                  <td>{{ item.nomrub }}</td>
                  <td>{{ item.qtdrub }}</td>
                  <td class="text-right">{{ item.vlrpag ? Number(item.vlrpag).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                </tr>
              </tbody>
              </table>
            </div>
            <div class="col-sm table-responsive">
              <table class="table" style="margin-bottom: 0;">
                <thead>
                <tr>
                  <th scope="col" style="width: 20%;">Rubrica</th>
                  <th scope="col" style="width: 40%;">Movimento</th>
                  <th scope="col" style="width: 20%;">Quantidade</th>
                  <th scope="col" style="width: 20%;" class="text-right">Valor (R$)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in resumoMovimento" :key="item.codmov">
                  <td>{{ item.codmov }}</td>
                  <td>{{ item.nommov }}</td>
                  <td>{{ item.qtdmov }}</td>
                  <td class="text-right">{{ item.vlrmov ? Number(item.vlrmov).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                </tr>
              </tbody>
              </table>
            </div>
          </div>
        </template>
      </uspCartao>
      <uspCartao titulo="Servidores" :fechado="listaServRecolher">
        <template slot="corpo">
          <form class="form-inline mb-3">
            <input
                type="text"
                class="form-control mr-3 col-sm-6"
                v-model="filtroTitulo"
                placeholder="Filtrar por nome ou número USP"
            />
            <button class="btn btn-primary mt-3 mt-sm-0 mr-3" @click.prevent="atualizarLista()">Pesquisar</button>
            <!--<button class="btn btn-secondary mt-3 mt-sm-0 mr-3" :disabled="desabilitarCarregarExclusoes" @click.prevent="carregarExclusoes()">Carregar exclusões</button>-->
          </form>
          <p>Lista limitada aos 20 primeiros servidores</p>
          <div class="table-responsive">
            <table class="table" style="margin-bottom: 0;">
              <thead>
                <tr>
                  <th scope="col" style="width: 10%;">Número USP</th>
                  <th scope="col" style="width: 10%;">Sequencial</th>
                  <th scope="col" style="width: 10%;">Grupo</th>
                  <th scope="col" style="width: 50%;">Nome</th>
                  <th scope="col" style="width: 10%;">Exclusão</th>
                  <th scope="col" style="width: 10%;"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in listaServidorFiltrada"
                  :key="item.codpes + '_' + item.numseqsrv + '_' + item.codgrpcon"
                >
                  <td>{{ item.codpes }}</td>
                  <td>{{ item.numseqsrv }}</td>
                  <td>{{ item.tipcon }}</td>
                  <td>{{ item.nompes }}</td>
                  <td>{{ item.tipmotexusrv | tipoExclusao }}</td>
                  <td class="text-right">
                    <a href="#" @click.prevent="abrirExclusao(item)" title="Exclusao">
                      <i class="fas fa-user-times"></i>
                    </a>
                    <a href="#barraServidor" @click="listarPagamentos(item)" title="Valores" class="ml-3">
                      <i class="fas fa-money-bill-alt"></i>
                    </a>
                    <a
                      href="#"
                      class="ml-3"
                      title="Memoria de calculo"
                      @click.prevent="mostrarLogs(item)"
                      data-toggle="modal"
                      data-target="#ProcLogs"
                      >
                      <i class="fas fa-sticky-note"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </uspCartao>
      <div id="barraServidor" style="height: 64px;"></div>
      <div class="card bg-light" v-show="servidorSelecionado">
        <div class="card-body">
          <div class="card-text row">
            <div class="col-3"><a @click.prevent="anterior()" href="#"><i class="fas fa-arrow-left" v-if="posicaoAtual > 0"></i></a></div>
            <div class="col-6 text-center">{{ servidorSelecionado }}</div>
            <div class="col-3 text-right"><a href="#" @click.prevent="proximo()"><i class="fas fa-arrow-right" v-if="posicaoAtual < listaServidor.length - 1"></i></a></div>
          </div>
        </div>
      </div>
      <div
        class="row mt-3"
        v-if="servidorSelecionado"
      >
        <div class="col-sm" v-if="processamento.tippcsfol == 'Diferença' || processamento.tippcsfol == 'Retroativa'">
          <uspCartao titulo="Diferenças processadas">
            <template slot="corpo">
              <div class="row mb-2">
                <!-- <div>{{ intermedRetro }}</div>
                <div>{{ intermedSelecionada }}</div>
                <div>{{ posicaoIntermed }}</div> -->
                <div class="col-1 pt-2 text-right">
                  <a @click.prevent="mesAnterior()" href="#" v-show="intermedRetro.length > 0 && posicaoIntermed > 0"><i class="fas fa-arrow-left"></i></a>
                </div>
                <div class="col-4">
                  <select class="form-control" v-model="intermedSelecionada" v-show="intermedRetro.length > 0" @change="encontraPosicaoIntermed()">
                    <option value=""></option>
                    <option :value="item.codfolpagrps" v-for="item in intermedRetro" :key="item.codfolpagrps">{{ item.codfolpagrps }}</option>
                  </select>
                </div>
                <div class="col-1 pt-2 text-left">
                  <a v-show="intermedRetro.length > 0 && posicaoIntermed < intermedRetro.length - 1" @click.prevent="mesProximo()" href="#"><i class="fas fa-arrow-right"></i></a>
                </div>
                <div class="col-6 pt-2 pr-4">
                  <div class="text-right mb-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="somenteDiferencas" value="1">
                      <label class="form-check-label">
                        Somente diferenças
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table table-responsive" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%;">Rubrica</th>
                    <th scope="col" style="width: 50%;">Nome</th>
                    <th scope="col" class="text-right" style="width: 10%;">Anterior (R$)</th>
                    <th scope="col" class="text-right" style="width: 10%;">Reprocessado (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in listaDiferencas"
                    :key="item.codrub"
                  >
                    <td>{{ item.codrub }}</td>
                    <td>{{ item.nomrub }}</td>
                    <td class="text-right">{{ item.vlrpag1 ? Number(item.vlrpag1).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                    <td :class="'text-right ' + (item.vlrpag1 == item.vlrpag2 ? 'text-primary' : Math.abs(Number(item.vlrpag1) - Number(item.vlrpag2)) < 1 ? 'text-warning' : Number(item.vlrpag1) > Number(item.vlrpag2) ? 'text-danger' : (Number(item.vlrpag1) < Number(item.vlrpag2) ? 'text-success' : ''))">{{ item.vlrpag2 ? Number(item.vlrpag2).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </uspCartao>
        </div>
        <div class="col-sm">
          <uspCartao titulo="Valores a pagar">
            <template slot="corpo">
              <table class="table table-responsive" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%;">Rubrica</th>
                    <th scope="col" style="width: 60%;">Nome</th>
                    <th scope="col" class="text-right" style="width: 20%;">Valor (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in pagamentos"
                    :key="item.codrub"
                  >
                    <td>{{ item.codrub }}</td>
                    <td>{{ item.nomrub }}</td>
                    <td class="text-right">{{ item.vlrpag ? Number(item.vlrpag).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </uspCartao>
        </div>
        <div class="col-sm">
          <uspCartao titulo="Movimentos gerados">
            <template slot="corpo">
              <table class="table table-responsive" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%;">Rubrica</th>
                    <th scope="col" style="width: 40%;">Nome</th>
                    <th scope="col" style="width: 20%;">Folha</th>
                    <th scope="col" class="text-right" style="width: 20%;">Valor (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in movimentos"
                    :key="item.codrub"
                  >
                    <td>{{ item.codrub }}</td>
                    <td>{{ item.nomrub }}</td>
                    <td>{{ item.codfolpagdst }}</td>
                    <td class="text-right">{{ item.vlrmov ? Number(item.vlrmov).toLocaleString(undefined, {minimumFractionDigits: 2}) : "" }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </uspCartao>
          <uspCartao titulo="Cadastros realizados">
              <template slot="corpo">
                <div class="text-center">
                    <div class="btn-group" role="group">
                        <router-link target="_blank" :to="{path: '/novaFolha/consulta', query: {tipo: 'FOLHA', codfolpag: processamento.codfolpag, codpes: listaServidor[posicaoAtual].codpes, numseqsrv: listaServidor[posicaoAtual].numseqsrv, dtaini: listaServidor[posicaoAtual].dtafchfol}}" class="btn-primary btn mr-3">Dados carregados</router-link>
                        <router-link target="_blank" :to="{path: '/novaFolha/consulta', query: {tipo: 'CONGELADO', codpes: listaServidor[posicaoAtual].codpes, dtaini: listaServidor[posicaoAtual].dtafchfol}}" class="btn-primary btn mr-3">Dados originais</router-link>
                        <router-link target="_blank" :to="{path: '/novaFolha/consulta', query: {tipo: 'ALTERACAO', codpes: listaServidor[posicaoAtual].codpes, dtaini: '2022-01-01', dtafim: listaServidor[posicaoAtual].dtafchfol}}" class="btn-primary btn mr-3">Alterações de dados</router-link>
                    </div>
                </div>
              </template>
          </uspCartao>
          <uspCartao titulo="Motivos" v-show="processamento.tippcsfol == 'Retroativa'">
            <template slot="corpo">
              <table class="table table-responsive" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%;">Início</th>
                    <th scope="col" style="width: 20%;">Fim</th>
                    <th scope="col">Motivo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in motivosRetro"
                    :key="item.dtainipagrta + '|' + item.motrta"
                  >
                    <td>{{ item.dtainipagrta }}</td>
                    <td>{{ item.dtalimpagrta }}</td>
                    <td>{{ item.nomtab }} {{ item.motrta }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </uspCartao>
        </div>
      </div>
      <uspModal :titulo="tituloLogs" ref="ProcLogs" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
        <template slot="body">
            <pre style="height: 600px; overflow-y: scroll; white-space: pre-wrap;">{{ logs }}</pre>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" @click="fecharModal('ProcLogs')">Fechar</button>
        </template>
      </uspModal>
      <uspModal :titulo="tituloExclusao" ref="modalExclusao" :maxWidth="windowWidth > 768 ? '75%' : '100%'">
        <template slot="body">
          <div class="row">
            <div class="form-group col-sm-12">
              <label>Tipo de motivo</label>
              <select v-model="objetoExclusao.tipmotexusrv" class="form-control">
                <option value=""></option>
                <option value="SUS">Suspensão</option>
                <option value="DES">Desligamento</option>
                <option value="FAL">Falecimento</option>
                <option value="ULP">Ultrapassado limite de pagamento</option>
                <option value="OUT">Outros</option>
              </select>
            </div>
          </div>
          <div class="row" v-if="objetoExclusao.tipmotexusrv == 'OUT'">
            <div class="form-group col-sm-12">
              <label>Detalhe do motivo</label>
              <textarea required v-model="objetoExclusao.epfmotexuout" class="form-control" style="height: 150px;"></textarea>
            </div>
          </div>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary" @click="fecharModal('modalExclusao')">Fechar</button>
            <button type="button" class="btn btn-primary" :disabled="processandoExclusao" data-dismiss="modal" @click="salvarExclusao()">Salvar</button>
        </template>
      </uspModal>
    </div>
  </section>
</template>
<script>
import NovaFolhaServico from "../dominio/novaFolha/NovaFolhaServico";
const api = NovaFolhaServico.build({});
export default {
  name: "Analise",
  computed: {
    listaDiferencas() {
        var self = this;
        var retorno = [];
        for (var i in self.diferencasPagamentos) {
            if (!self.somenteDiferencas || self.diferencasPagamentos[i].vlrpag1 != self.diferencasPagamentos[i].vlrpag2) {
                retorno.push(self.diferencasPagamentos[i]);
            }
        }
        return retorno;
    },
    codfolpag() {
      var self = this;
      return self.$route.query.codfolpag;
    },
    numseqpcsfol() {
      var self = this;
      return self.$route.query.numseqpcsfol;
    }
  },
  data() {
    return {
      listaServidor: [],
      filtroTitulo: "",
      procEdicao: {},
      pagamentos: [],
      diferencasPagamentos: [],
      listaServRecolher: false,
      somenteDiferencas: false,
      servidorSelecionado: "",
      posicaoAtual: 0,
      intermedRetro: [],
      intermedSelecionada: "",
      posicaoIntermed: 0,
      tituloExclusao: "",
      objetoExclusao: {},
      processandoExclusao: false,
      movimentos: [],
      motivosRetro: [],
      processamento: {},
      listaServidorFiltrada: [],
      tituloLogs: "",
      logs: "",
      desabilitarCarregarExclusoes: false,
      resumoPagamento: [],
      resumoMovimento: [],
      windowWidth: window.innerWidth
    };
  },
  filters: {
    tipoExclusao: function(tipo) {
      if (tipo == "SUS") return "Suspensão";
      if (tipo == "DES") return "Desligamento";
      if (tipo == "FAL") return "Falecimento";
      if (tipo == "ULP") return "Ultrapassado limite de pagamento";
      if (tipo == "OUT") return "Outros";
      return "";
    }
  },
  methods: {
    normalizar: function(valor) {
        return valor ? (valor+'').toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "";
    },
    mostrarLogs: function(item) {
      var self = this;
      self.tituloLogs = item.codpes + "/" + item.numseqsrv + "/" + item.codgrpcon + " " + item.nompes;
      self.logs = "";
      api.obterMemoriaCalc(item).then(function(resposta) {
        if (resposta.length > 0) {
          self.logs = resposta[0].mmocal;
          self.$refs['ProcLogs'].openModal();
        }
      });
    },
    atualizarLista() {
      var self = this;
      self.listaServidorFiltrada = [];
      self.$forceUpdate();
      var retorno = [];
      for (var i in self.listaServidor) {
        if (self.filtroTitulo == "" 
            || self.listaServidor[i].codpes == self.filtroTitulo
            || self.normalizar(self.listaServidor[i].nompes).indexOf(self.normalizar(self.filtroTitulo)) != -1) {
          retorno.push(self.listaServidor[i]);
          if (retorno.length >= 20) break;
        }
      }
      self.listaServidorFiltrada = retorno;
    },
    abrirExclusao: function(item) {
      var self = this;
      self.tituloExclusao = item.codpes + "/" + item.numseqsrv + " - " + item.nompes;
      self.objetoExclusao = Object.assign(self.$route.query, JSON.parse(JSON.stringify(item)));
      self.$refs['modalExclusao'].openModal();
    },
    salvarExclusao: function() {
      var self = this;
      self.processandoExclusao = true;
      api.atualizarExclusaoServ(self.objetoExclusao).then(function() {
        self.$refs['modalExclusao'].closeModal();
        self.listar();
      }).catch((erro) => alert(erro.mensagem))
      .finally(function() {
        self.processandoExclusao = false;
      });
    },
    fecharModal(ref){
      this.$refs[ref].closeModal();
    },
    anterior: function() {
      var self = this;
      self.posicaoAtual--;
      self.carregar();
    },
    proximo: function() {
      var self = this;
      self.posicaoAtual++;
      self.carregar();
    },
    carregar: function() {
      var self = this;
      //self.filtroTitulo = self.listaServidor[self.posicaoAtual].codpes;
      self.servidorSelecionado = self.listaServidor[self.posicaoAtual].nompes;
      self.pagamentos = [];
      self.movimentos = [];
      self.motivosRetro = [];
      self.diferencasPagamentos = [];
      self.intermedRetro = [];
      self.intermedSelecionada = "";
      self.$forceUpdate();
      api.listarPagamentosServ(Object.assign({}, self.$route.query, self.listaServidor[self.posicaoAtual])).then(lista => {
        self.pagamentos = lista;
      });
      api.listarMovimentosServ(Object.assign({}, self.$route.query, self.listaServidor[self.posicaoAtual])).then(lista => {
        self.movimentos = lista;
      });
      api.listarMotivosRetro(Object.assign({}, self.$route.query, self.listaServidor[self.posicaoAtual])).then(lista => {
        self.motivosRetro = lista;
      });
      if (self.processamento.tippcsfol == 'Retroativa') {
        api.listarIntermedRetro(Object.assign({}, self.$route.query, self.listaServidor[self.posicaoAtual])).then(lista => {
        self.intermedRetro = lista;
        if (self.intermedRetro.length > 0) {
          self.intermedSelecionada = self.intermedRetro[0].codfolpagrps;
          self.posicaoIntermed = 0;
          self.carregarIntermed();
        }
      })
      } else {
        api.listarAnaliseDif(Object.assign({}, self.$route.query, self.listaServidor[self.posicaoAtual])).then(lista => {
        self.diferencasPagamentos = lista;
      });
      }
    },
    mesAnterior: function() {
      var self = this;
      self.posicaoIntermed--;
      self.carregarIntermed();
    },
    mesProximo: function() {
      var self = this;
      self.posicaoIntermed++;
      self.carregarIntermed();
    },
    carregarIntermed() {
      var self = this;
      self.intermedSelecionada = self.intermedRetro[self.posicaoIntermed].codfolpagrps;
      self.diferencasPagamentos = [];
      self.$forceUpdate();
      api.listarIntermedCompar(Object.assign({}, self.$route.query, self.listaServidor[self.posicaoAtual], {'codfolpagrps': self.intermedSelecionada})).then(lista => {
        self.diferencasPagamentos = lista;
      });
    },
    encontraPosicaoIntermed() {
      var self = this;
      self.posicaoIntermed = self.intermedRetro.map(x => x.codfolpagrps).indexOf(self.intermedSelecionada);
      self.carregarIntermed();
    },
    carregarExclusoes() {
        var self = this;
        self.listaServidorFiltrada = [];
        self.$forceUpdate();
        self.desabilitarCarregarExclusoes = true;
        api.carregarExclusoes(self.$route.query).then(function() {
            self.desabilitarCarregarExclusoes = false;
            self.exibirSomenteExcluidos = true;
            self.listar();
        });
    },
    listar() {
      var self = this;
      api.listarServidores(self.$route.query).then(function(lista) {
        self.listaServidor = lista;
        for (var i in self.listaServidor) {
          self.listaServidor[i].posicao = i;
        }
        self.atualizarLista();
      });
    },
    listarPagamentos(item) {
      var self = this;
      self.posicaoAtual = item.posicao;
      self.carregar();
    },
    informacoes(item) {
      var self = this;
      self.procEdicao = item;
      self.procEdicao.detalhes = "Executado por: <strong>" + item.nompes + "</strong> em: <strong>" + item.dtacad + "</strong><br/>" + 
      "Data de fechamento: <strong>" + item.dtafchfol + "</strong><br>" +
      "Tipo de processamento: <strong>" + (item.tippcs == "R" ? "Real" : "Simulação") + "</strong><br>";
      if (item.tippcs == "S" && item.tipsmu) {
        for (var i in self.simulacoes) {
          if (self.simulacoes[i].id == item.tipsmu) {
            self.procEdicao.detalhes += "Tipo de simulação: <strong>" + self.simulacoes[i].nome + "</strong><br>";
          }
        }
      }
      var parametros = JSON.parse(item.pmepcssmu);
      for (var parametro in parametros) {
        if (parametro.startsWith("s_") && parametro != 's_id') {
          var descricao = parametro;
          var valor = parametros[parametro];
          if (descricao == "s_escopo") {
            descricao = "Escopo";
            if (valor == "I") valor = "Individual";
            if (valor == "") valor = "USP";
          } else if (descricao == "s_codund") {
            descricao = "Unidade";
          } else if (descricao == "s_eleitos") {
            descricao = "Eleitos";
          } else if (descricao == "s_gc") {
            descricao = "Grupo(s) de condição";
            if (valor.indexOf("false") != -1) {
              var novoValor = "";
              var tokens = valor.split(',');
              for (var j in tokens) {
                if (tokens[j] == "true" && j != 0) {
                  novoValor += ", " + j;
                }
              }
              valor = novoValor.substring(2);
            } else {
              descricao = "";
            }
          } else if (descricao == "s_codpes") {
            descricao = "Número USP";
          } else if (descricao == "s_numseqsrv") {
            descricao = "Sequencial";
          } else if (descricao == "s_aux_creche") {
            descricao = "Novo valor para auxílio creche (R$)";
          } else if (descricao == "s_educacao_esp") {
            descricao = "Novo valor para educação especial (R$)";
          } else if (descricao == "s_porcentual") {
            descricao = "Porcentual de reajuste (%)";
          }
          if (descricao != "") {
            self.procEdicao.detalhes += descricao + ": <strong>" + valor + "</strong><br>";
          }
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted() {
    var self = this;
    console.log(self.$route);
    self.listar();
    api.listarProcs(self.$route.query).then(function(lista) {
      self.processamento = lista[0];
      self.informacoes(lista[0]);
    });
    api.listarResumoPagamento(self.$route.query).then(function(lista) {
      self.resumoPagamento = lista;
    });
    api.listarResumoMovimento(self.$route.query).then(function(lista) {
      self.resumoMovimento = lista;
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  }
};
</script>
<style>
</style>
